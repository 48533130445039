.banner .carousel .slide video,
.banner .carousel .slide img {
  object-fit: cover;
  height: 280px !important;
  border-radius: 25px;
  margin-bottom: 5px;
  position: relative;
  padding-bottom: 7px;
}
.banner-services {
  padding: 0 10px;
}

.banner-services .carousel .slide img {
  // height: auto !important;
}
.bot_rad .carousel .slide video,
.bot_rad .carousel .slide img {
  border-radius: 0;
  height: 220px !important;
}

.banner .carousel .slide .ove {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  bottom: 0;
}
