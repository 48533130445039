@font-face {
  font-family: "FreightSans";
  src: url("./fonts/FreightSans\ Pro\ Medium.ttf");
}

.new_page {
  background-color: #000;
  min-height: 100vh;
  padding: 0 5px;
  font-family: "FreightSans";
  padding-bottom: 50px;
  position: relative;
  z-index: 200;

  .banner_image {
    .banner {
      width: 100%;
      height: 300px;
      border-radius: 30px;
      object-fit: cover;
    }
  }

  .quick_services {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;

    flex-direction: column;

    button {
      border: 1px solid #ffd28d;
      background-color: transparent;
      color: #e7e7e7;
      padding: 10px 20px;
      width: 250px;
      border-radius: 5px;
      font-size: 17px;
      letter-spacing: 1.3px;
      box-shadow: inset 0 0 0 0 #ffd28d;
      transition: all 0.3s ease-in-out;
    }
    button:hover {
      box-shadow: inset 250px 0px 0 0 #ffd28d;
      color: #000;
    }
  }

  .our_offering {
    margin-top: 30px;

    h3 {
      font-size: 17px;
      letter-spacing: 1.3px;
      color: #e7e7e7 !important;
      font-weight: 300 !important;
    }

    .slick-slide {
      padding: 10px;
    }
    .slick-slide img {
      position: relative;
    }
    .image_carousel {
      position: relative;
      border-radius: 15px;
      overflow: hidden;
    }
    .img_overlay {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
    .img_title {
      font-size: 17px;
      letter-spacing: 1.3px;
      color: #e7e7e7 !important;
      font-weight: 300 !important;
      position: absolute;
      bottom: 0;
      left: 10px;
    }
  }

  .language_box {
    display: flex;
    justify-content: end;
    .btn_lang {
      text-align: center;
      width: 40px;
      height: 40px;
      border: 1px solid #ffd28d;
      border-radius: 50%;
      cursor: pointer;
      color: #ffd28d;
      line-height: 40px;
      font-size: 15px;
      font-weight: 700;
      margin: 6px;
      margin-right: 15px;
    }
  }
  .services_box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    a {
      text-decoration: none !important;
      width: 45%;
      text-align: center;
      margin-top: 10px;
      background: #ffd28d;
      color: #000;
      border-radius: 4px;
      padding: 5px;
    }
  }
  .box_lang {
    position: absolute;
    width: 100%;
    background: #000;
    height: 100%;
    z-index: 100;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 11121;

    img {
      margin: 110px;
    }
    h4 {
      margin-bottom: 20px;
      font-size: 18px;
      text-align: center;
      color: #e7e7e7;
      letter-spacing: 2px;
      line-height: 1.5;
    }
    ul {
      list-style: none;
      li {
        margin-bottom: 15px;
        width: 150px;
        margin: auto;
        text-align: center;
        margin-top: 10px;
        border: 1px solid #ffd28d;
        background: #000;
        color: #ffd28d;
        border-radius: 4px;
        padding: 5px;
        box-shadow: inset 0 0 0 0 #ffd28d;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
      li:hover {
        box-shadow: inset 150px 0px 0 0 #ffd28d;
        color: #000;
      }
      .lang_active {
        color: #000;
        background-color: #ffd28d;
      }
    }
  }
  .box_lang.not_show {
    height: 0;
  }

  .display-list li a,
  .animated-background {
    background: linear-gradient(to right, #2c2828 8%, #1f1c1c 18%, #262222 33%);
  }
}

.new_footer {
  padding: 12px 0;
  background-color: #252525;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10000;
  left: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 15%;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        width: 26px;
        height: 26px;
        position: relative;
      }
      span {
        top: -8px;
        position: absolute;
        background: #fff;
        width: 20px;
        font-size: 15px;
        text-align: center;
        font-weight: normal;
        right: -3px;
        border-radius: 50%;
        height: 20px;
        line-height: 20px;
        color: #000;
      }
    }
  }
}

.arabic {
  direction: rtl;

  .our_offering .img_title {
    right: 10px;
    left: initial;
  }

  .our_offering h3 {
    text-align: right;
    padding: 0 7px;
  }
}
