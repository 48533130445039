@font-face {
  font-family: "FreightSans";
  src: url("../../fonts/FreightSans\ Pro\ Medium.ttf");
}
.food.book-table p {
  color: #e7e7e7 !important;
}

.food {
  background-color: #000;
  color: #e7e7e7 !important;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;

  font-family: "FreightSans" !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  li {
    font-family: "FreightSans" !important;
  }
  //   width: 95%;
  //   margin: auto;

  .box_food {
    width: 95%;
    margin: auto;

    a {
      position: relative;
      width: 100%;
      margin-bottom: 35px;
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 30px;
      position: relative;
    }
    .booking_img {
      height: 250px !important;
    }
    .overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
    .sub_title {
      position: absolute;
      bottom: 0;
      left: 20px;
      color: #e7e7e7 !important;
      font-size: 17px;
      letter-spacing: 2px;
      font-weight: 400;
    }
  }

  .text_food {
    color: #e7e7e7;
    position: absolute;
    bottom: 0;
    left: 16px;
    text-align: left;

    h5 {
      margin: 0;
      font-weight: 400;
      letter-spacing: 2px;
    }
    p {
      margin: 5px 0;
      letter-spacing: 2px;
    }
  }

  .Search-bar {
    input {
      background-color: transparent !important;
      border-color: #ffd28d;
      color: #ffd28d !important;
    }
    i {
      color: #ffd28d !important;
    }
  }
  .display-list li a,
  .animated-background {
    background: linear-gradient(to right, #2c2828 8%, #1f1c1c 18%, #262222 33%);
  }
  .fixedSearch {
    top: 0 !important;
    background: #000 !important;
  }

  .reg-btn,
  .add-cart-post.new .add-cart-post-inner .txt-pnl .flex-div .add-button,
  .inline-list.search li.active button {
    background-color: #ffd28d !important;
    color: #000 !important;
    background-image: none;
  }
  .inline-list.search li button {
    border-color: #ffd28d !important ;
    background-color: transparent !important;
    color: #ffd28d !important;
  }
  .add-cart-post .add-cart-post-inner {
    background-color: #000;
    color: #e7e7e7 !important;
    img {
      border-radius: 10px !important;
    }

    .txt-pnl h3 {
      color: #ffd28d !important;
    }
    .txt-pnl p,
    .txt-pnl h2 {
      color: #e7e7e7 !important;
    }
  }
  .your-order-list {
    p,
    span {
      color: #e7e7e7 !important;
    }
    svg {
      fill: #e7e7e7 !important;
    }
  }
  .accordion .accordion-item .accordion-collapse .accordion-body {
    background-color: #252525;
    color: #e7e7e7 !important;
    border: none;
    .extra,
    h6,
    p {
      color: #e7e7e7 !important;
    }
  }
  .accordion .accordion-item .accordion-header button {
    border: #252525;
    b {
      color: #e7e7e7 !important;
    }
    svg {
      fill: #e7e7e7;
    }
    svg #Line_41,
    svg #Line_40 {
      stroke: #e7e7e7 !important;
    }
  }
  .count-order-amount {
    background-color: transparent !important;
    border-color: #ffd28d !important;
    span,
    button {
      color: #ffd28d !important;
    }
  }
  .search_page .display-list.v2.three-items li a p {
    color: #e7e7e7 !important;
  }
  .add-cart-post .add-cart-post-inner .txt-pnl p {
    margin-bottom: -4px !important;
  }
  .bc-btns {
    display: flex;
    justify-content: space-between;
    a {
      background-color: #ffd28d;
      color: #000;
      /* margin: 5px auto; */
      margin: 0;
      padding: 5px 12px;
      text-align: center;
      font-size: 17px;
      border-radius: 10px;
      width: 48%;
      margin-top: 10px;
    }
  }
  p.bk-title {
    color: #ffd28d !important;
  }
  .book-table p {
    color: #e7e7e7 !important;
  }
  .quantity {
    span {
      border-color: #ffd28d !important;
      i {
        color: #ffd28d !important;
      }
    }
  }
  .res_info {
    padding: 0 16px;
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reg-btn {
      padding: 5px !important;
      font-weight: normal;
      margin-bottom: 0px;
      font-size: 13px;
      min-width: 170px;
    }
  }
  .res_name {
    h5 {
      margin: 0;
      font-size: 17px;
    }
    p {
      margin: 0;
    }
  }
  .text-black {
    color: #ffd28d !important;
  }
  .viewcart .view-cart-header {
    background-color: #252525 !important;
    border: none;
  }
  .viewcart .view-cart-body {
    background-color: #000;
    h3 {
      color: #ffd28d !important;
    }
  }
  .viewcart .view-cart-header p {
    color: #ffd28d !important;
  }
  .search_categories_supermarket {
    i {
      color: #ffd28d !important;
    }
  }
  .supermarket_name {
    color: #e7e7e7 !important;
    i {
      color: #e7e7e7 !important;
    }
  }
  .subermarket_categories_slider li.active p {
    color: #e7e7e7 !important;
  }

  .add-cart-post.new .add-cart-post-inner .txt-pnl .flex-div .add-button {
    border-color: #ffd28d !important;
  }
  .spinner-border {
    border: 0.25em solid #ffd28d;
    border-right-color: transparent;
  }
  .scan p {
    color: #ffd28d;
  }
  .scan button {
    color: #ffd28d;
    background-color: #252525 !important;
  }
  .display-list.v2.car-list li button p {
    color: #ffd28d !important;
  }
  .car-drop-flex .car-drop .dropdown-toggle {
    border-color: #ffd28d !important;
    color: #ffd28d !important;
  }
  .box_ {
    background-color: #000 !important;
    .title_ {
      color: #ffd28d !important;
    }
  }
  .your-order-list li:not(:last-child),
  .your-order-list {
    border-color: #ffd28d !important;
  }
  svg #Path_313,
  svg #Icon_awesome-receipt,
  svg #Path_307,
  svg #Path_314 {
    fill: #e7e7e7 !important;
  }
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: #ffd28d !important;
  }
  .orders .flex p,
  .orders .flex .top-title span,
  .orders .datetime span {
    color: #ffd28d !important;
  }
  .about-us .info {
    background-color: #252525;
  }
  .about-us .cls-1lo,
  .about-us .cls-1ex,
  .about-us .cls-1info {
    fill: #ffd28d !important;
  }
  .about-us .desc,
  .about-us table td,
  .about-us table th {
    color: #e7e7e7;
  }
  .about-us .info p {
    color: #ffd28d !important;
  }
}

.food-modal .modal-content {
  background-color: #0d0d0d !important;

  .title {
    color: #ffd28d !important;
  }
  .react-tel-input .flag-dropdown,
  .body-container .input-pnl input,
  .body-container .input-pnl textarea {
    border-color: #ffd28d !important;
  }
  input::placeholder,
  textarea::placeholder,
  input {
    color: #e7e7e7 !important;
  }
  .reg-btn {
    background-color: #ffd28d !important;
    color: #000 !important;
    background-image: none;
  }
  .modal-text-container.sub-menu,
  .modal-text-container.sub-menu .price-container p {
    color: #e7e7e7 !important;
  }

  .count-order-amount {
    border-color: #ffd28d !important ;
    background-color: transparent !important;
    color: #ffd28d !important;
  }
  .modal-text-container.sub-menu .price-container .count-order-amount button,
  .modal-text-container.sub-menu .price-container p {
    color: #ffd28d !important;
  }
  .close-btn .close-btn-wrap {
    background-color: #ffd28d !important;
  }
  .extras-heading {
    background-color: #252525;

    .text {
      color: #ffd28d !important;
    }
  }

  .reg-btn {
    border-color: #ffd28d !important;
  }
  .full-heading {
    background-color: #252525 !important;
    margin-bottom: 10px;
    color: #e7e7e7;
    h3 {
      color: #ffd28d !important;
    }
  }
  .bk-date p,
  .bk-time p {
    color: #e7e7e7 !important;
  }
  input[type="date"].form-control {
    color: #e7e7e7 !important;
  }
  .check-list-container .check-list li {
    background-color: #0d0d0d !important;
    color: #e7e7e7;
  }
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: #ffd28d !important;
    accent-color: #ffd28d !important;
  }
  #start-camera {
    background-color: #ffd28d !important;
  }
}
.arabic {
  direction: rtl;

  .box_food .sub_title {
    right: 20px;
    left: initial;
  }
  .text_food {
    right: 16px;
    left: initial;
    text-align: right;
  }
}
