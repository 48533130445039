.food {
  main {
    padding-top: 0 !important;
    background-color: transparent !important;

    p {
      color: #ffd28d;
    }

    .code-section .right button {
      background-color: #ffd28d !important;
      color: #000 !important;
      background-image: none;
    }
    #Icon_awesome-tags {
      fill: #ffd28d !important;
    }

    .code-section {
      background-color: #252525 !important;
    }
    .code-section .middel input {
      background-color: #000 !important;
      border-color: #ffd28d !important;
      color: #ffd28d !important;
    }
    .form-control {
      background-color: #000 !important;
      border-color: #ffd28d !important;
      color: #ffd28d !important;
    }
    .form-control:disabled,
    .form-control[readonly],
    .react-tel-input .flag-dropdown {
      border-color: #ffd28d !important;
      background-color: #252525 !important;
    }
    .full-heading {
      background-color: #000 !important;
      color: #ffd28d !important;

      h2 {
        color: #ffd28d !important;
      }
    }
    .agree a {
      color: #ffd28d !important;
    }
  }
  .add-post-container.v2 .add-cart-post.payment.v2 .add-cart-post-inner {
    background-color: #0d0d0d !important;

    .count-order-amount {
      border-color: #ffd28d;
      background-color: transparent !important;
    }
    .count-order-amount p,
    .count-order-amount button {
      color: #ffd28d !important;
    }
  }
}
.food-modal {
  .additional_title,
  .additional_header i {
    color: #ffd28d !important;
  }
  .additional_button {
    background-color: #0d0d0d !important;
    width: 100%;

    button {
      background-color: #ffd28d !important;
      color: #000 !important;
    }
  }
  .add-cart-post .add-cart-post-inner {
    background-color: #000000 !important;

    .txt-pnl h3,
    .txt-pnl p,
    .txt-pnl h2 {
      color: #e7e7e7 !important;
    }
    .add-button {
      background-color: #ffd28d !important;
      color: #000 !important;
      background-image: none;
    }
  }
}
