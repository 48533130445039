/*color*/
:root {
  --color: #fff;
  --color_t: #fff;
  --color_m_c: #fff;
  --color_m_s: #fff;
  --header_color: #fff;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}

$white: #ffffff;
$black: #111111;
$color: #027eb4;
$red: #f11027;
$grey: #bdbaba;
$dark-color: #00638e;
$light-color: #4fb8e6;

@mixin trans1 {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

@mixin grad {
  background-image: -moz-linear-gradient(
    -45deg,
    var(--color) 0%,
    var(--color) 100%
  );
  background-image: -webkit-linear-gradient(
    -45deg,
    var(--color) 0%,
    var(--color) 100%
  );
  background-image: linear-gradient(0deg, var(--color_t) 0%, var(--color) 100%);
  background-color: #000;
}
.take_img {
  position: relative;
}
#canvas {
  height: 165px;
  width: 223px;
  position: absolute;
  top: 2px;
  left: 27%;
  border-radius: 6px;
}
.video_img {
  height: 160px;
  width: 220px;
  text-align: center;
  margin: auto;
  /* margin: 0rem 0rem; */
  border: 2px dashed rgb(214 214 214);
  background-color: #000;
  position: relative;
}
#start-camera {
  color: white;
  padding: 8px 28px 8px 37px;
  border-radius: 5px;
  border: unset;
  width: 175px;
  background: var(--color);
  background-position: 15px 10px;
  background-size: 20px;
  background-repeat: no-repeat;
  font-size: 12px;
  font-family: Arial;
  letter-spacing: 0.5px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
#click-photo {
  display: block;
  border: unset;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-decoration: none;
  width: 11rem;
  color: #6d6b6b;
  border: unset;
  font-size: 12px;
  background: unset;
  margin-top: 2rem;
}

.err_msg {
  margin: 0;
  margin-top: -15px;
  display: block;
  margin-bottom: 11px;
  color: #f34545;
  font-size: 12px;
}
.home_page {
  h3 {
    color: #fff !important;
  }
  ul {
    color: #fff !important;
  }
}
.btn.disabled {
  background-color: var(--color);
  border-color: var(--color);
}
.btn:disabled {
  background-color: var(--color);
  border-color: var(--color);
}
input[type="checkbox"],
input[type="radio"] {
  accent-color: var(--color);
}

* {
  margin: 0;
  padding: 0;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.f-12 {
  font-size: 12px !important;
}

.f-16 {
  font-size: 16px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.text-gray {
  color: #bdbaba !important;
}

body,
html,
main {
  -webkit-font-smoothing: antialiased;
}

a,
a:focus,
button,
button:focus {
  outline: 0 !important;
  box-shadow: none;
}

a {
  display: inline-block;
  text-decoration: none !important;
}

.bg-layer {
  opacity: 1;
  background-size: cover;
  background-position: center;
}

.bg-layer {
  position: absolute;
  top: 0;
  left: 0;
}
.carousel .carousel-status {
  display: none;
}
.bg-layer.restaurant {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.bg-layer,
iframe,
video,
source {
  height: 100%;
  width: 100%;
}

img,
picture,
svg {
  max-width: 100%;
}

.full-div {
  display: inline-block;
  width: 100%;
}

.half-div {
  display: inline-block;
  width: 50%;
  float: left;
}

.load_more_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .load_more {
    border: none;
    background-color: var(--color);
    color: #fff;
    border-radius: 15px;
    padding: 4px 12px;
  }
}
.txt-right,
.text-right {
  text-align: right;
}

.txt-center,
.txt-centr,
.text-center {
  text-align: center;
}

.txt-left,
.text-left {
  text-align: left;
}

.flex-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
}

.flex-div-sm {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: inline-block;
    text-align: center;
  }
}

.flex-div-xs {
  display: flex;
  justify-content: space-between;

  @media (max-width: 575px) {
    display: inline-block;
    text-align: center;
  }
}

button:focus {
  box-shadow: none !important;
}

// body,
// html {
//   min-height: 100%;
//   height: auto;
// }

img {
  max-width: 100%;
}

div,
img,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
span {
  // @include trans1();
}

ul {
  padding-left: 0;
}

.pd-r-0 {
  padding-right: 0;
}

.pd-l-0 {
  padding-left: 0;
}

body {
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**** divider ***/
.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: block;
  clear: both;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: block;
  clear: both;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;

  &.dark {
    border-top: solid 1px #212529;

    opacity: 0.25;
  }
}

::placeholder {
  color: #bdbaba !important;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: #bdbaba !important;
  font-size: 14px;
}

::-ms-input-placeholder {
  color: #bdbaba !important;
  font-size: 14px;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

p a {
  color: $black;
  font-weight: 600;

  &:hover {
    color: $color;
  }
}
.laundry_page {
  input[type="time"] {
    padding-top: 9px !important;
    display: flex;
  }
  input[type="date"] {
    padding-top: 9px !important;
    display: flex;
  }
}
input#destination {
  padding-left: 35px;
}
input#count {
  background-image: url(./assets/images/timer.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 18px 12px;
}
.textarya::placeholder {
  font-size: 12px;
}
.total_coast {
  font-size: 12px;
  color: #1d1d1d;
  font-weight: 500;
  margin-top: 1rem;
}
.laundray_check {
  display: flex;
  align-items: center;
  label {
    margin: 0 6px;
    color: #6d6b6b;
    font-weight: 600;
    font-size: 13px;
  }

  .accented {
    --size: 1.4rem;
    width: 18px;
    height: 18px;
    accent-color: var(--color);
  }
}
select,
select.form-control,
input.form-control {
  border-radius: 5px;
  height: 42px;
  // line-height: 42px !important;

  &:focus {
    box-shadow: none;
    border-color: var(--color);
  }
}

.padd-div {
  padding-left: 15px;
  padding-right: 15px;
}

h2 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;

  &.blck {
    color: $black;
  }
}

h3 {
  color: var(--color) !important;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;
}

.reg-anchor {
  color: $color;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;
  display: inline-block;
  text-decoration: underline !important;
}

/* Navbar Starts Here
===================== */
.navbar {
  background-color: var(--header_color);
  // Changes were made here
  // Position changed from fixed to sticky
  // you should change the z-index of this

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  height: 70px;
  // @include grad();
  padding: 10px 15px 10px 15px;
  display: flex !important;
  justify-content: space-between !important;
  max-width: 767px;
  margin: 0 auto;

  .back-btn-container {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    /* justify-content: flex-start; */
    .back-btn {
      padding: 2px 4px;
      width: 4.5rem;
      border: 1px solid;
      border-color: var(--color);
      background-color: transparent;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      align-items: center;
      font-size: 12px;

      /* margin-top: -8px; */
      p {
        margin: 0;
        padding: 0;
        font-size: inherit !important;
        color: var(--color);
      }

      svg {
        margin-right: 5px;
        fill: var(--color);
      }
    }
  }

  .navbar-brand {
    font-size: 15px;
    font-weight: 600;
    margin-top: 0px;
    display: block;
    margin-right: 0 !important;

    span {
      color: var(--color);
    }
  }

  .nav-link {
    padding: 0;
  }

  .dropdown {
    // border: 1px solid black;
    border-radius: 5px;
    background-color: transparent;
    height: 25.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color);
    width: 70px;

    .nav-link {
      // color: $dark-color;
      font-weight: normal;
      padding: 0px 8px;
      color: var(--color);

      img {
        margin-bottom: 2px;
        margin-right: 2px;
      }
    }

    .dropdown-menu {
      padding: 0;
      padding-top: 2px;
      min-width: 96px !important;

      a {
        padding: 2px 10px;
        // color: $dark-color;
        border-bottom: 1px solid gray;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        justify-content: space-evenly;
        font-weight: normal;

        // &:hover {
        //   color: $white;
        //   background-color: $dark-color;
        // }
      }
    }

    .dropdown-item {
      padding: 2px 20px !important;

      .flag {
        margin-right: 5px;
      }

      span {
        width: 20px;
        padding-left: 4px;
      }
    }
  }
}
.order-model {
  color: var(--color);
}

.taxii .f-dropdown > span {
  background: transparent !important;
  border: 1px solid #afafaf !important;
  margin-top: 5px;
  margin-left: 10px;
}
.taxii .f-dropdown.open ul {
  margin-top: 10px !important;
}

.taxii .f-dropdown > span img {
  width: 45px !important;
}
.taxii .f-dropdown ul li a img {
  width: 45px !important;
  object-fit: fill !important;
}
/* Navbar Ends Here
===================== */

/* Footer Starts here
===================== */
.footer {
  border-radius: 10px;
  @include grad();
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  height: 48px;
  align-items: center;
  // Changes made here
  position: fixed;
  bottom: 0;
  // left: 15px; c#
  // right: 15px;
  z-index: 122;
  // max-width: 737px;
  width: 94%;
  left: 3%;
  transition: none !important;
  margin: 0 auto;

  .search-img,
  .whatsapp-img {
    width: 23px;
  }

  .active-footer {
    border-color: #fff;

    // padding: 10px 0;
    // margin-bottom: -5px;
    // width: 46px;

    svg {
      // height: 32px !important;
      margin-bottom: 3px;
    }
  }

  @media (max-width: 767px) {
    // left: 15px; c#
    // right: 15px;
  }

  a {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid transparent;

    &.card-btn {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: $white;
      border-radius: 5px;
      width: 45px !important;
      padding: 0 7px;
      height: 35px;

      &:before {
        position: absolute;
        content: "";
        left: 5px;
        top: 10px;
        bottom: 10px;
        width: 1px;
        background-color: $dark-color;
        border-radius: 25px;
      }

      span {
        color: $dark-color;
        font-size: 14px;
        font-weight: 700;
      }

      &::after {
        bottom: -10px;
      }
    }

    &::after {
      position: absolute;
      left: -3px;
      bottom: -12px;
      right: -3px;
      border-radius: 25px;
      height: 5px;
      background-color: $white;
      content: "";
      box-shadow: 0 0px 10px rgba(255, 255, 255, 0.6);
      opacity: 0;
      @include trans1();
    }

    &.active {
      &::after {
        opacity: 1;
      }
    }
  }
}

/* Footer ENds Here
=================== */
.text-header {
  position: relative;
  width: 100%;
  height: 140px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 14px;
    font-weight: 600;
    color: $color;
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.img-header {
  position: relative;
  width: 100%;
  height: 212px;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  // changed  // @media (min-width:576px) {
  // @media (min-width: 320px) {
  //   height: 220px;
  // }

  // @media (min-width: 400px) {
  //   height: 250px;
  // }

  p {
    color: $dark-color;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    position: relative;
    z-index: 2;
  }
}

.carousel {
  .slide {
    img {
      height: 230px !important;
    }

    video {
      height: 230px !important;
      width: 100% !important;
      object-fit: cover !important;
    }

    .carousel-restaurant {
      border-radius: 15px;

      img {
        height: 208px !important;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      }

      video {
        height: 208px !important;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      }
    }
  }
}

.house {
  .carousel {
    .slide {
      img {
        height: 212px !important;
      }
    }
  }
}

// .carousel-restaurant {
//   img {
//     height: 100% !important;
//   }
// }
svg {
  #Line_40 {
    stroke: var(--color) !important;
  }

  #Line_41 {
    stroke: var(--color) !important;
  }

  #Path_313 {
    fill: var(--color) !important;
  }

  #Path_314 {
    fill: var(--color) !important;
  }

  #Icon_awesome-receipt {
    fill: var(--color) !important;
  }

  #Layer_2 {
    fill: var(--color) !important;
  }

  #Path_307 {
    fill: var(--color) !important;
  }
}

.your-order-list {
  display: inline-block;
  width: 100%;
  border: 1px solid var(--color);
  margin-bottom: 13px;
  border-radius: 5px;

  li {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 7px 10px;
    justify-content: space-between;

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color);
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      color: #6d6b6b;
    }

    i {
      color: $color;
      font-size: 16px;
      font-weight: 600;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      color: #1d1d1d;
      font-weight: 500;
    }
  }
}

.your-order-list.spa {
  display: inline-block;
  width: 100%;
  border: 1px solid var(--color);
  margin-bottom: 13px;
  border-radius: 5px;

  li {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 7px 10px;
    justify-content: space-between;

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color);
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      color: #6d6b6b;
    }

    i {
      color: $color;
      font-size: 16px;
      font-weight: 600;
      margin-right: 5px;
    }

    span {
      color: #1d1d1d;
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.menu-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  align-items: flex-start;
  margin: 0;

  li {
    // display: inline-block;
    height: 130px;
    width: 33.333333%;
    padding: 0 5px;
    margin-bottom: 15px;

    a {
      display: inline-block;
      width: 100%;
      text-align: center;
      position: relative;

      .img-pnl {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        background-color: "#000000";
        @include grad();
        margin: 0 auto 10px;
        align-items: center;

        img {
          max-width: 60px;
          max-height: 60px;
        }
      }

      .txt-pnl {
        display: inline-block;
        width: 100%;
        padding: 0 5px;

        p {
          font-size: 12px;
          color: $black;
          font-weight: 600;
          height: 36px;
          overflow: hidden;
          margin: 0;
          position: relative;
        }
      }
    }
  }
}

#trans-anime {
  left: -30px;
  opacity: 0;

  &.trans {
    left: 0;
    opacity: 1;

    p {
      opacity: 1;
      left: 0;
    }
  }

  p {
    left: -40px;
    opacity: 0;
    @include trans1();
  }
}
.taxi_book iframe {
  height: calc(100vh - 140px) !important;
}
.taxi_book iframe body {
  background-color: red !important;
}
.pure-button-primary-progressive {
  background-color: red !important;
}
.taxi_book .nav_bar_header {
  z-index: 1000;
  position: fixed !important ;
}
.taxi_book app-nav-bar {
  position: fixed !important ;
  width: 100%;
  z-index: 1000;
}
/* Regular Button */
.reg-btn {
  font-size: 14px;
  font-weight: 700;
  color: $white;
  background-color: var(--color);
  padding: 8px 15px;
  border-radius: 10px;
  min-width: 188px;
  margin-bottom: 20px;
  border-color: var(--color);
  // text-transform: capitalize;

  &.service {
    min-width: unset;
    text-align: center;
    font-size: 10px;
    width: max-content !important;
    border-radius: 6px;
    font-weight: 400;
    // padding: 4px 5px 4px 15px;
    padding: 4px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    // margin-right: 10px;
  }

  &.service.v2 {
    min-width: unset;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    width: 87px !important;
    border-radius: 5px;
    // padding: 4px 5px 4px 15px;
    padding: 5px 0 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-right: 10px;
  }

  &:focus,
  &:hover,
  &:active {
    color: $white !important;
    background-color: var(--color) !important;
    border-color: var(--color);
  }

  &.big {
    width: 100%;
  }

  &.large {
    width: 20rem;
    font-weight: 500;
    border-radius: 10px;
    font-size: 12px;

    &.more-pad {
      padding: 12px 15px;
    }

    &.font-dark {
      font-weight: 700;
    }
  }

  &.more-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
  }

  &.order {
    min-width: 176px !important;
    padding: 6px 2px !important;
    border-radius: 5px;
  }

  &.fw-normal {
    font-weight: 400;
  }

  &.order-btn {
    min-width: 190px !important;
    padding: 10px 2px !important;
    border-radius: 5px;
    font-weight: 500;

    &.bg-text {
      font-size: 16px !important;
    }

    &.less-pad {
      padding: 8px 2px !important;
    }
  }

  &.book {
    min-width: 136.5px !important;
    padding: 10px 2px !important;
    border-radius: 5px;

    &.lg {
      min-width: 160px !important;
      padding: 8px 2px !important;
    }
  }

  &.small {
    padding: 5px 15px;
    min-width: auto;
    margin: 0;
    font-weight: 500;
    position: relative;
    bottom: -90px;
  }
}

.nav_bar_header {
  background: #efefef !important;
  position: fixed !important;
  top: 0px !important;
}

.full-heading {
  padding: 10px 15px;
  background-color: $white;
  margin-bottom: 20px;

  &.flex {
    display: flex;
    justify-content: space-between;
  }

  &.grey-text {
    h2 {
      color: #6d6b6b;
    }
  }

  &.grey-bg {
    background-color: #f6f6f6;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #313131;
    margin: 0;

    &.rd {
      color: $red;
    }

    &.grey {
      color: #9d9d9d;
    }

    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: var(--color);
    margin: 0;

    &.rd {
      color: $red;
    }
  }
}

.payemtn-type-list {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  padding-right: 4px;
  margin-bottom: 0.5rem;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;

  li {
    display: inline-block;
    width: 50%;
    padding: 0 6px 12px 0;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;

    a {
      display: inline-block;
      width: 100%;
      // padding: 7px 10px;
      border-radius: 10px;
      border: 1px solid #707070;
      text-align: center;
      color: #9d9d9d;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      height: 5.8rem;
      padding: 1rem 0.3rem;

      img {
        max-width: 50px;
        max-height: 50px;
      }

      svg {
        width: 35px;
        height: 35px;
      }

      p {
        display: inline-block;
        width: 100%;
        margin: 0;
        font-size: 10px;
        margin: 0;
        margin-top: 10px;
        text-transform: capitalize;
      }

      // &:focus {
      //   background-color: $color;
      //   -webkit-filter: grayscale(0%);
      //   filter: grayscale(0%);
      //   color: $white;
      //   border-color: $color;
      // }
    }

    &.active {
      a {
        background-color: var(--color) !important;
        border-color: var(--color) !important;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        color: $white;

        p {
          color: white;
        }
      }
    }

    &.active.gray {
      a {
        background-color: $color;
        border-color: $color;
        color: $white;

        img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }

        p {
          color: white;
        }
      }
    }
  }
}
.mod_img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}
.droupzo_ {
  padding: 10px;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  // border: 2px solid #ddd;
  border-radius: 10px;
  min-height: 100px;

  .drop_c {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      position: relative;
      width: 45%;
      margin: 5px;
      display: flex;
      border: 1px solid #ddd;

      img {
        width: 100%;
        height: 100px;
        object-fit: cover;
      }

      i {
        position: absolute;
        font-size: 12px;
        top: -8px;
        left: -7px;
        color: #fff;
        cursor: pointer;
        background: var(--color);

        height: 17px;
        width: 17px;
        text-align: center;
        line-height: 17px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.spa_price {
  display: flex;
  justify-content: start;
  align-items: center;
  .discount_spa {
    margin-right: 5px;
    font-size: 13px;
    text-decoration: line-through;
    color: #777;
    margin-top: 3px;
  }
}
.ma_images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  img {
    width: 45%;
    height: 100px;
    object-fit: cover;
    margin: 4px;
    display: flex;
    border: 1px solid #ddd;
  }
}
.check-list-container {
  display: inline-block;
  width: 100%;
  padding: 0;

  .check-list {
    display: inline-block;
    width: 100%;
    position: relative;

    li {
      display: inline-block;
      width: 100%;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      background-color: $white;

      p {
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  .check-list.v3 {
    li {
      p:not(:last-child) {
        width: 100%;
        font-weight: 500;
      }

      p:not(:first-child) {
        width: 80%;
        font-weight: 400;
      }
    }
  }
}

.display-list {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0;

  li {
    display: inline-block;
    padding: 0 7.5px;
    margin-bottom: 30px;

    button,
    a {
      display: flex;
      width: 100%;
      border: none;
      padding: 0;
      text-align: center;
      justify-content: center;
      align-items: end;
      position: relative;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);

      .img-pnl {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 10px;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .bg-layer {
        opacity: 0.51;
        border-radius: 10px;
        z-index: 2;
        // background: -moz-linear-gradient(
        //   top,
        //   rgba(0, 99, 142, 0.35) 0%,
        //   rgba(0, 50, 71, 0.55) 100%
        // );
        // background: -webkit-linear-gradient(
        //   top,
        //   rgba(0, 99, 142, 0.35) 0%,
        //   rgba(0, 50, 71, 0.55) 100%
        // );
        // background: linear-gradient(
        //   to bottom,
        //   rgba(0, 99, 142, 0.35) 0%,
        //   rgba(0, 50, 71, 0.55) 100%
        // );
        background-color: rgba(0, 0, 0, 0.3);
      }

      p {
        font-size: 13px;
        color: $white;
        font-weight: 800;
        margin: 0;
        z-index: 2;
        margin-bottom: 10px;
        letter-spacing: 1.3px;
        position: absolute;
        bottom: 0;
      }
    }
  }

  &.one-item {
    li {
      width: 100%;
      float: none;
      margin-bottom: 16px;
      padding: 0 0.6rem;

      a {
        height: 208px;
        padding: 0 6rem;

        box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%) !important;
      }
    }
  }

  &.two-items {
    li {
      width: 47.5%;
      float: left;

      a {
        height: 160px;
      }
    }
  }

  &.three-items {
    li {
      width: 33.333333%;
      float: left;

      a {
        height: 120px;
      }
    }
  }

  &.three-items.v3 {
    li {
      a {
        img {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          border-radius: 10px;
          z-index: 1;
          height: 100%;
          width: 100%;
          // obje: no-repeat;
          object-position: center center;
          object-fit: cover;
        }
      }
    }
  }

  &.car-list {
    padding: 0 24px !important;

    li {
      padding: 0 12px !important;
      width: 50%;
      margin-bottom: 1rem !important;

      button,
      a {
        height: auto;
        display: inline-block;
        background-color: transparent;
        box-shadow: none;
        text-align: center;

        .img-pnl {
          position: relative;
          height: 160px;
          margin-bottom: 5px;
          box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.5);
          object-fit: cover;
        }

        p {
          color: $black;
        }
      }
    }
  }
}
.suber_market_cat p {
  position: relative !important;
}
// .modal-body {
//   padding: 10px;
// }
.display-list.v2 {
  li {
    margin-bottom: 10px;
    padding: 0 8px;
  }

  &.two-items {
    padding: 0px;

    li {
      width: 50%;
      float: left;
      // height: 175px;
      // height: 140px;
      padding: 0 12px;
      min-height: 140px;
      position: relative;

      a {
        height: 140px;
        // height: 175px;
        box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
      }
    }
  }

  &.three-items {
    li {
      a {
        height: 100px;
        box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);

        p {
          font-size: 11px;
          font-family: "Poppins-SemiBold";
          font-weight: 500;
        }
      }
    }
  }

  &.car-list {
    li {
      button,
      a {
        .img-pnl {
          height: 130px;
          margin-bottom: 5px;
          background-size: contain;
          background-repeat: no-repeat;
        }

        p {
          color: #1d1d1d;
          margin-bottom: 0px;
          font-size: 12px;
          letter-spacing: 0.6px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
  }
}
.car_rental .flex-div.car-drop-flex {
  justify-content: center !important;

  .car-drop {
    margin: 0 5px;
  }
}
.restaurant-list {
  li {
    a {
      align-items: end !important;
    }
  }
}

.inline-list {
  display: inline-block;
  width: 100%;
  margin: 0;
  // margin-bottom: 10px;
  padding: 0 15px;

  li {
    display: inline;

    a {
      padding: 0px 25px;
      margin-right: 10px;
      background-color: $white;
      border: 1px solid $color;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0px;
      border-radius: 6px;
      color: $color;
    }

    &.active {
      a {
        @include grad();
        color: $white;
      }
    }
  }
}
.text-black {
  color: #000 !important;
  text-align: initial;
}
.inline-list.search {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  height: 40px;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    display: inline;

    button {
      padding: 4px 25px;
      margin-right: 10px;
      background-color: $white;
      border: 1px solid var(--color);
      color: var(--color);
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 10px;
      border-radius: 6px;
      min-width: 200px;
    }

    &.active {
      button {
        @include grad();
        color: $white;
      }
    }

    // &:hover {
    //   button {
    //     background-color: $white !important;
    //     border: 1px solid var(--color) !important;
    //     color: var(--color) !important;
    //   }
    // }
  }
}

.inline-post {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0;

  li {
    display: inline-block;
  }

  &.flex {
    display: flex;
  }

  &.full {
    li {
      width: 100%;
    }
  }

  &.half {
    li {
      width: 50%;
      float: left;
    }
  }

  &.three-list {
    li {
      width: 33.33333%;
      float: left;
      padding: 0 15px 15px;
    }
  }
}

.Search-bar {
  padding: 20px;
  position: relative;

  input {
    background-color: inherit;
    text-align: center;
    border: 1px solid var(--color);
    // background-image: url(../src/assets/images/icons/icon-search-blue.png);
    background-repeat: no-repeat;
    background-position: 10px center;
    font-size: 14px;

    &:focus {
      box-shadow: none !important;
    }
  }

  i {
    position: absolute;
    top: 12px;
    left: 35px;
    font-size: 17px;
    color: var(--color);
  }

  &.black {
    input {
      border-color: var(--color);
    }
  }
}

.Search-bar.v2 {
  padding: 20px 12px;

  input {
    background-color: #fcfafa !important;
  }

  &.pad-md {
    padding: 15px 12px;
  }
}

.Search-bar.less {
  padding: 0px 20px 10px 20px;
}

.Search-bar.less.searchPage {
  padding: 0px 10px 10px 10px;
  margin-bottom: 5px;

  input {
    height: 45px !important;
  }

  i {
    position: absolute;
    top: 15px;
    left: 35px;
    font-size: 20px;
    color: var(--color);
  }
}

.voucher-panel {
  display: flex;
  padding: 15px 15px 20px;
  justify-content: space-between;
  background-color: #f6f6f6;

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;

    @media (max-width: 424px) {
      font-size: 12px;
    }
  }

  input {
    width: 150px;
    height: 40px;
    margin: 0;
    margin-right: 10px;

    @media (max-width: 424px) {
      width: 120px;
    }
  }

  button {
    padding: 0;
    height: 40px;
    margin: 0;
  }
}

.searched-pop {
  padding: 0 5px 10px;
  text-align: left;

  li {
    padding: 0 10px 15px;
    width: 138px;

    a {
      background-color: $white;
      display: flex;
      justify-content: center;
      padding: 7px;
      border-radius: 6px;
      border: 1px solid #027eb4;
      font-size: 14px;
      font-weight: 600;

      img {
        width: 20px;
        margin-right: 7px;
      }
    }
  }
}

// Carousile
// .carousel {
//   margin-bottom: 30px;

//   img {
//     width: 100%;
//   }

//   .bg-layer {
//     opacity: 0.32;
//     background: -moz-linear-gradient(
//       top,
//       rgba(0, 50, 71, 0.56) 0%,
//       rgba(0, 99, 142, 1) 100%
//     );
//     background: -webkit-linear-gradient(
//       top,
//       rgba(0, 50, 71, 0.56) 0%,
//       rgba(0, 99, 142, 1) 100%
//     );
//     background: linear-gradient(
//       to bottom,
//       rgba(0, 50, 71, 0.56) 0%,
//       rgba(0, 99, 142, 1) 100%
//     );
//   }

//   .carousel-item {
//     @include trans1();
//   }
// }

// .carousel-control-prev,
// .carousel-control-next {
//   display: none;
// }

// .carousel-indicators {
//   bottom: -15px;

//   button {
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background-color: #d8d8d8;
//     border: none;
//     margin-left: 2.5px;
//     margin-right: 2.5px;
//     @include trans1();

//     &.active {
//       background-color: $color;
//       width: 25px;
//       border-radius: 5px;
//     }
//   }
// }

// Carousile

// Gerneral Post
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: #fff !important;
}

.btn-primary.is_green:not(:disabled):not(.disabled):active {
  background-color: #4cdf30 !important;
  border-color: #4cdf30 !important;
  color: #fff !important;
}

.general-post {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 320px;
  padding: 0 15px 20px;

  .closed-tag {
    position: absolute;
    right: 55px;
    top: -9px;
    background-image: url(../src/assets/images/closed-tag.png);
    background-size: 100% 100%;
    width: 69px;
    height: 80px;
    display: none;
  }

  &.closed {
    .closed-tag {
      display: inline-block;
    }
  }

  .img-pnl {
    display: inline-block;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;

    img {
      border-radius: 15px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  .txt-pnl {
    display: inline-block;
    width: 100%;
    border-radius: 15px;
    padding-left: 0px;
    text-align: initial;

    h2 {
      color: $color;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    p {
      color: #6d6b6b;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.general-post-container {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #9b9b9b;
  margin-bottom: 25px;

  &.closed {
    .closed-tag {
      display: inline-block;
    }
  }

  .general-post {
    max-width: 100%;

    .txt-pnl {
      h2 {
        font-size: 18px;
        color: var(--color);
      }
    }
  }
}

// Issue # 2 skimmer size
// .restaurant-post {
//   width: 470px;
// }
.general-post-container.restaurant-post {
  .animated-background.img-pnl {
    min-height: 213px;
  }
}

// Gerneral Post
.count-order-amount {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid var(--color);
  width: 120px;
  line-height: 0;
  height: 35px;
  padding: 7px 10px;
  border-radius: 6px;

  p {
    font-size: 16px;
    margin: 0;
    height: auto;
    line-height: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--color);
    font-size: 14px;
    padding: 0;
    width: 20px;
    height: 20px;

    &:active {
      color: white !important;
      background-color: var(--color) !important;
      border-color: none;
    }

    &:hover {
      color: white !important;
      background-color: var(--color) !important;
      border-color: none;
    }

    &:disabled {
      color: var(--color) !important;
      background-color: transparent !important;
      border-color: none;
    }
  }
}
.res_options {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-right: 4px;
    margin-top: -6px;
  }
  .form-check-input {
    position: relative !important;
    margin: 0 !important;
  }
}
// Text Post
.text-post {
  display: inline-block;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;

  .img-pnl {
    display: inline-block;
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
  }

  .txt-pnl {
    display: inline-block;
    width: 100%;
    background-image: url(../src/assets/images/txt-bg.png);
    background-size: 100% 100%;
    padding: 20px;
    border-radius: 0 0 8px 8px;

    p {
      margin: 0;
      font-size: 14px;
      color: $black;
      font-weight: 600;
    }
  }

  .Add-new-pnl {
    display: inline-block;
    width: 100%;
    padding: 20px;
    border-radius: 0 0 8px 8px;
  }
}

// Text Post
.scan {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  video {
    height: auto !important;
    width: auto !important;
  }

  // img {
  //   margin-top: 40vh;
  // }
  #reader {
    border: none !important;
    img {
      display: none;
    }
    #reader__scan_region {
      min-height: 10px !important;
    }
    #html5-qrcode-anchor-scan-type-change {
      display: none !important;
    }
  }

  section {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      position: relative;
      transform: scaleX(-1);
      width: 100% !important;
    }
  }

  h4 {
    color: #006390;
    font-size: 18px;
    margin-top: 3rem;
    line-height: 28px;
    letter-spacing: 0.8px;
  }
  p {
    color: #000;
    margin: 0;
    font-family: "Poppins-Medium";

    font-size: 14px;
    margin: 0rem 0 10px;
    line-height: 28px;
    letter-spacing: 0.8px;
    margin-top: 25px;
  }
  button {
    border: none;
    background: #000;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
// Add Cart Post
.add-cart-post {
  width: 100%;
  // display: inline-block;
  margin-bottom: 1rem;
  position: relative;
  padding: 0 15px;

  .add-cart-post-inner {
    // display: inline-block;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    justify-content: space-between;
    display: flex;
    background-color: $white;
    padding: 10px;
    position: relative;

    @media (max-width: 424px) {
      // display: inline-block;
      // flex-direction: column;
      // width: 100%;
    }
  }

  .img-pnl {
    width: 40%;

    @media (max-width: 424px) {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 100%;
    }

    img {
      border-radius: 10px;
    }

    // @media(max-width:575px) {
    //   width: 150px;

    //   img {
    //     width: 160px;
    //     height: 160px;
    //     max-height: 160px;
    //   }
    // }
  }

  .txt-pnl {
    width: 60%;
    padding-left: 10px;

    @media (max-width: 575px) {
      width: calc(100% - 160px);
      padding-left: 0;
    }

    @media (max-width: 424px) {
      width: 100%;
      padding-left: 0;
      padding-top: 15px;
    }

    h2 {
      font-size: 20px;
      color: $black;
      margin: 0px 0 10px;
      font-weight: 500;

      @media (max-width: 575px) {
        font-size: 16px;
      }
    }

    p {
      font-size: 13px;
      color: $black;
      margin: 0;
      height: 63px;
      overflow: hidden;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      color: var(--color);
      margin: 0;
      // margin-top: 7px;
      font-weight: 600;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
  }

  .reg-btn {
    display: none;
  }

  .post-delet-btn {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    background-color: transparent;
    border: none;
    font-size: 22px;
    font-weight: 400;
    color: $color;
  }
}

.add-post-container {
  // changed
  // &.add-cart {
  //   .reg-btn {
  //     display: inline-block;

  //   }

  //   .count-order-amount {
  //     display: none;
  //   }
  // }

  &.full-post {
    .add-cart-post-inner {
      display: inline-block;
      width: 100%;

      .txt-pnl,
      .img-pnl {
        width: 100%;

        @media (max-width: 575px) {
          img {
            width: auto;
            height: auto;
            max-height: unset;
          }
        }
      }

      .txt-pnl {
        padding: 15px 15px 0;

        h2 {
          margin: 0px 0 10px;
        }
      }
    }
  }
}

// Add Cart Post
.mt7 {
  margin-top: 7px !important;
}
.mtop-7 {
  margin-top: -7px !important;
}
// Accodian
.accordion {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;

  .accordion-item {
    .accordion-header {
      margin: 0;

      button {
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        border: 1px solid;
        border-radius: 10px 10px 0 0;
        border-color: $color;
        font-size: 14px;
        font-weight: 700;
        color: $color;
        padding: 10px 25px 10px 15px;

        &.collapsed {
          border-color: #6d6b6b;
          color: #6d6b6b;
          border-radius: 10px;
        }

        p {
          margin: 0;

          b {
            color: var(--color);
          }
        }

        i {
          position: absolute;
          right: 5px;
          top: 7px;
          font-size: 20px;
          font-weight: 700;
          color: $color;
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        background-color: #f6f6f6;
        border: 1px solid $color;
        padding: 15px 15px 10px;
        border-radius: 0 0 10px 10px;
        border-top: none;

        .img-pnl {
          width: 55px;
        }

        .text-pnl {
          width: calc(100% - 55px);
          padding-left: 10px;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          color: #6d6b6b;
          margin-bottom: 5px;
        }

        h6 {
          font-size: 14px;
          font-weight: 600;
          color: $black;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.order-accordian {
  .accordion {
    border: 1px solid $color;
    border-radius: 10px;

    .accordion-item {
      &:last-child {
        .accordion-header {
          button {
            border-radius: 0;
            border-left: 0;
            border-top: 0;
            border-right: 0;

            &.collapsed {
              border-bottom-color: transparent;
            }
          }
        }

        .accordion-body {
          border-radius: 0 0 10px 10px;
          border-bottom: none;
        }
      }

      .accordion-header {
        button {
          border-radius: 0;
          border-left: 0;
          border-top: 0;
          border-right: 0;
        }
      }

      .accordion-body {
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }

  .accordion.v2 {
    border: 1px solid var(--color);
    border-radius: 10px;

    .total-body {
      padding-block: 20px;

      p {
        font-size: 12px;
        font-weight: 300;
        color: #777;
        margin-bottom: 5px !important;
      }

      h6 {
        font-size: 12px;
        font-weight: 300;
        color: #777;
        margin-bottom: 5px !important;
      }
    }

    .accordion-item {
      &:last-child {
        .accordion-header {
          button {
            border-radius: 0;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            border-bottom: 0;

            &.collapsed {
              border-bottom-color: transparent;
            }
          }
        }

        .accordion-body {
          border-radius: 0 0 10px 10px;
          border-bottom: none;
        }
      }

      .accordion-header {
        button {
          border-radius: 0;
          border-left: 0;
          border-top: 0;
          border-right: 0;
          padding: 10px 15px 10px 7px;
        }
      }

      .accordion-body {
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }
}

// Accodian
/* Extra Css Starts header
========================== */
body {
  // background-color: #f0f0f0;
}

main {
  width: 100%;
  // padding: 15px 0 75px;
  position: relative;
  background-color: #f0f0f0;
  min-height: calc(100vh - 40px);
  // min-height: 100vh;
  // height: auto;
  max-width: 767px;
  margin: 0 auto 0;
  margin-bottom: 40px;
  padding-top: 70px !important;
  // padding-bottom: 50px;

  // &.pad-top-0 {
  //   padding-top: 0 !important;
  // }

  &.h-aut {
    min-height: initial !important;
  }
  &.pt-3 {
    padding-top: 70px !important;
  }

  // Changed from white to gray
  &.bg-white {
    background-color: #f0f0f0 !important;
  }

  &.back-white {
    background-color: white !important;
  }

  &.back-fe {
    background-color: #fefffe !important;
  }

  &.home-main.landing-panel {
    height: 87vh;
    min-height: 87vh;
    width: 500px;

    @media (max-width: 500px) {
      width: 100vw;
    }

    margin: 0 auto;
  }
}

.chat-container {
  display: inline-block;
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid #ccc;
  min-height: calc(100vh - 170px);
  height: auto;
  padding: 20px;

  p {
    color: #6d6b6b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.viewcart {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: $white;
  border-radius: 20px 20px 0 0;

  .view-cart-header {
    padding: 0.95rem 1rem 0.95rem 1.5rem;
    border-radius: 20px 20px 0 0;
    background-color: var(--color);
    display: flex;
    justify-content: space-between;
    color: $white;
    font-size: 14px;
    font-weight: 400;

    p {
      color: white !important;
      margin: 0;
    }

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: $white;
      color: var(--color);
      font-size: 12px;
      margin-right: 25px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .view-cart-body {
    padding: 6px 15px;

    h3 {
      color: var(--color);
      margin: 0;
    }
  }
}

.viewcart.v2 {
  position: absolute;
}

// Modal
.modal-content {
  justify-content: center;
  margin-top: 5px;

  .modal-body {
    padding: 100px 0 0px;
    height: 450px;
    position: relative;
  }

  .close-btn {
    padding: 0;
    background-color: transparent !important;
    border: none;
    color: var(--color);
    position: absolute;
    font-size: 30px;
    right: 0px;
    top: -10px;
    z-index: 555;

    .close-btn-wrap {
      position: absolute;
      right: 3px;
      top: 12px;
      z-index: -1;
      width: 25px;
      height: 25px;
      border-radius: 20px;

      background-color: white;
    }
  }

  // added this line
  .close-btn.img {
    top: -20px;
    right: -10px;
    font-size: 30px;
    color: var(--color);

    .close-btn-wrap {
      position: absolute;
      right: 3px;
      top: 12px;
      z-index: -1;
      width: 25px;
      height: 25px;
      border-radius: 20px;

      background-color: white;
    }
  }
}

// Car Rental modal
.car-rental-modal .modal-body,
.view-cart-modal .modal-body {
  padding: 0;
  // margin-top: 30px;
  height: unset;
  position: static;

  .close-btn {
    right: 0px;
    top: -11px;
    font-size: 35px;

    &.car {
      right: 5px;
    }
  }
}

.car-rental-modal {
  .modal-content {
    border-radius: 10px;
  }
}

@media (min-width: 576px) {
  .modal-xs {
    .modal-sm {
      max-width: 180px;
    }
  }
}

// Modal
.booking-order-detail {
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  align-items: center;

  .img-pnl {
    width: 85px;
    margin: 0 !important;

    img {
      border-radius: 10px;
      width: 100%;
    }
  }

  .txt-pnl {
    width: calc(100% - 55px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;

    p {
      font-size: 14px;
      color: #6d6b6b;
      margin: 0;
      font-weight: 600;
      margin: 0 5px;
    }

    h3 {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.dot-list {
  padding-left: 20px;
}

.dropdown.show button,
.dropdown button,
.dropdown button.show,
.dropdown button:focus {
  background-color: transparent !important;
  border: 1px solid $color !important;
  color: $color !important;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none !important;
}

.dropdown .dropdown-menu {
  padding: 0;
  min-width: 100%;

  a {
    padding: 7px 10px;
    font-size: 14px;
    color: var(--color);
    font-weight: 600;
    // &:focus,
    // &:hover {
    //   color: $white;
    //   background-color: $color;
    // }
  }
}

.color-btn-list {
  padding-top: 20px;

  li {
    display: inline-block;

    button {
      padding: 0;
      width: 25px;
      height: 25px;
    }
  }
}

.landing-panel {
  position: relative;
  overflow: hidden;
  overflow-y: hidden;

  .home-screen-panel {
    background: url("https://app.thedigitalhotelier.com/frontend/images/side-back.webp");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    // height: 38rem;

    /*  .home-screen-panel-inner {
      position: relative;

      h1 {
        font-size: 22px;
        color: #1d1d1d;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: #1d1d1d;
        font-weight: 600;
      }
    } */
  }
}

.display-list li a,
.animated-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #dddddd 8%, #ccc 18%, #ddd 33%);
  background-size: 400px 52px;
  height: auto;
  // &.img-pnl{
  //   min-height: 300px;
  // }
}

.display-list.v3 li a,
.animated-background.v3 {
  animation: none;
}

.general-post {
  .animated-background.img-pnl {
    min-height: 300px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.animated-background {
    min-height: 18px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}

// .add-cart-post .img-pnl{
//   animation-duration: 4s;
//   animation-fill-mode: forwards;
//   animation-iteration-count: infinite;
//   animation-name: placeHolderShimmer;
//   animation-timing-function: linear;
//   background: #f6f7f8 ;
//   background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
//   background-size: 400px 52px;
//   min-height: 30px;
// }
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none !important;
}
.bg-skimmer {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

// changed
.content {
  margin-block: auto;
  // max-height: 700px;
  // height: 100%;
  width: 100%;
  position: relative;
  // overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 500px) {
  .content {
    // width: 100vw;
    // max-height: 100vh;
  }
}

.container-wrap {
  display: grid;
  align-items: center;
  // justify-content: center;
  // height: 100vh;
}

.navigate {
  cursor: pointer;
}

//contains the image in the pop up
.modal-img-container {
  display: grid;
  justify-content: center;

  img {
    height: 291px !important;
    object-fit: cover;
  }
}

.modal-img-container.br {
  box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;

  img {
    width: 435px;
  }
}

.modal-img-container.v2 {
  img {
    width: auto !important;
    height: auto !important;
  }
}

.modal-img-container.cart-img {
  display: grid;
  justify-content: center;

  &.img-pnl {
    max-width: 100%;
  }
}

.modal-content .add-cart-post-inner {
  bottom: -80px;
}

.car-rental-modal .add-cart-post-inner,
.view-cart-modal .add-cart-post-inner {
  bottom: 0;
}

// custom width for modal
@media screen and (min-width: 300px) {
  .custom-width {
    .modal-dialog {
      max-width: 430px;
      /* New width for default modal */
    }

    .modal-content {
      .modal-body {
        height: auto;
        padding-top: 0;
      }
    }
  }

  .custom-width.img {
    padding-top: 7rem !important;

    .modal-dialog {
      max-width: 450px;
      /* New width for default modal */
    }

    .modal-content {
      .modal-body {
        height: auto;
        padding-top: 0;
      }
    }
  }
}

.custom-width {
  .modal-dialog {
    z-index: 1000;
    width: 90%;
    margin: auto;
    height: 100%;
  }
}

.custom-width.v2 {
  .modal-dialog {
    width: 96%;
  }
}

.custom-width.img {
  .modal-dialog {
    width: 90%;
  }

  .modal-content {
    .modal-body {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    max-height: 340px;
  }
}
.form-button-error {
  display: none !important;
}

.custom-width.img.v2 {
  .modal-content {
    max-height: unset;
    border-radius: 10px;
  }
}

.modal-text-container.sub-menu {
  padding: 8px;

  .price-container,
  .extras-headin,
  .full-div,
  .text-1,
  .text-2 {
    padding: 0 15px;
  }

  color: #313131;

  h2 {
    padding-left: 0;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin-bottom: 0.3rem;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    font-size: 10px;
  }

  .price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: var(--color);
      font-size: 12px;
      font-weight: 600;
      margin: 0;
    }

    .count-order-amount {
      width: 100px;
      line-height: 0;
      height: 25px;
      padding: 0 10px;
      border-radius: 6px;

      p {
        font-size: 14px;
        margin: 0;
        margin-top: 2px;
        height: auto;
      }

      button {
        background-color: transparent;
        border: none;
        color: var(--color);
        font-size: 10px;
        padding: 0;
      }
    }

    .count-order-amount.v2 {
      padding: 2px 20px;
    }
  }

  .middel .sub-title {
    color: #313131;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 20px;
    background-color: #f6f6f6;
    padding: 7px 30px 7px 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .middel .group .p1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    color: #313131;
    font-size: 12px;
    margin: 10px 0;
  }

  .middel input {
    width: 88%;
    margin: auto;
    margin-top: auto;
    display: flex;
    padding: 5px 5px 5px 20px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 15px;
    color: #6d6b6b;
    font-size: 12px;
  }

  .reg-btn {
    &.small {
      bottom: 0px;
      margin-block: 20px;
    }
  }

  .cart-btn-container {
    margin-top: 10px;
  }
}

.supermarket_name {
  width: 90%;
  margin: 10px auto;
  display: block;
  color: var(--color);
}
.suber_market_cat.display-list.v2.three-items li a {
  box-shadow: none !important;
  background: none !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  height: auto;
  .img-pnl {
    position: relative !important;
  }
  img {
    position: relative !important;
  }
  p {
    // Regular Regular

    font-size: 11px;
    font-family: "Poppins-SemiBold";
    // font-weight: 500;
    text-align: center;
    margin-top: 15px !important;
  }
}
.supermarket_name span {
  cursor: pointer;
}
.otp_div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  padding: 15px;

  .butons_opt {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    button {
      min-width: 170px !important;

      // margin: 0 5px;
    }
    .canc {
      background: transparent;
      color: var(--color);
    }
  }

  .verification-input__character--default {
    border-radius: 8px;
    border-color: var(--color) !important;
  }
  h5 {
    color: var(--color);
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
  }
  p {
    text-align: left;
    font-size: 14px;
    margin-bottom: 20px;
    color: #000;
  }
  button {
    // margin-top: 20px;
  }
}
.supermarket_name i {
  color: var(--color);
  // transform: rotate(180deg);
  margin: 0px 4px;
}
.loading li a {
  height: 120px !important;
}
.search_categories_supermarket {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Search-bar {
    width: 80%;
  }

  .Search-bar.less {
    padding: 0px 10px 10px 20px;
  }

  .icone_category {
    width: 20%;
    padding-right: 15px;
    display: flex;
    justify-content: space-around;

    span {
      font-size: 25px;
      color: var(--color);
      cursor: pointer;
      margin: 0 2px;
      opacity: 0.6;
    }
    span.active {
      opacity: 1;
    }
  }
}
.subermarket_categories_slider {
  display: flex;
  height: auto !important;
  li {
    margin: 0 5px;
  }
  .category_info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 65px;
    cursor: pointer;
    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
    p {
      font-size: 10px;
      font-weight: 500;
      margin-top: 4px;
      width: 100%;
      text-align: center;
      white-space: initial;
    }
  }
  li.active {
    p {
      color: var(--color);
    }
  }
}
.subermarket_items {
  display: flex;
  flex-wrap: wrap;
  .add-post-container {
    width: 100%;
  }
  .add-post-container.th {
    width: 48%;
    .add-cart-post {
      padding: 0 20px;
    }
    .add-cart-post.new .add-cart-post-inner {
      height: auto;
      flex-direction: column;
      box-shadow: none;

      .img-pnl {
        box-shadow: none;
        width: initial !important;
        height: initial !important;
        img {
          width: 155px !important;
          height: 155px !important;
        }
      }
      .txt-pnl {
        width: 100%;
        h2 {
          font-size: 14px !important;
          height: 42px !important;
          overflow: hidden;
          white-space: initial;
          line-height: 1.4;
        }
      }
      .flex-div {
        flex-direction: column;
        button {
          width: 100%;
          margin: auto;
          font-size: 11px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          padding: 0;
        }
        h3 {
          color: #000 !important;
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.super_link {
  align-items: end !important;
  justify-content: center !important;
  padding: 0px !important;
}

.s_title {
  color: var(--color);
  font-size: 12px;
  font-weight: 400;
}
.items_div {
  margin-top: 125px;
}
.divhtml p {
  margin: 0 !important;
}
.fixedSearch {
  position: fixed;
  z-index: 100;
  background: #fff;
  width: 100%;
  top: 70px;
  left: 0;
}
.not_active {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 90%;
  margin: auto;
}
@media (max-width: 500px) {
  .img-pnl.cover {
    width: 125px !important;
    height: 125px !important;

    img {
      width: 125px !important;
      height: 125px !important;
      object-fit: cover;
    }
  }
}

.flag {
  width: 15px;
  // margin-right: 5px;
}

.cart-container {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -5px;
  width: 15px;
  height: 15px;
  background-color: var(--color);
  border-radius: 50%;
  right: -8px;

  .count {
    color: $white;
    margin-left: 5px;
    font-weight: bold;
    font-size: 10px;
    padding-inline: 0px !important;
  }
}

svg {
  #Path_292 {
    fill: var(--color) !important;
  }

  #Path_293 {
    fill: var(--color) !important;
  }

  #Path_294 {
    fill: var(--color) !important;
  }
}

.add-cart-post.new .add-cart-post-inner {
  height: 125px;
  padding: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .img-pnl {
    width: 125px !important;
    height: 125px !important;
    margin: 0;

    img {
      border-radius: 5px 0 0 0;
      width: 125px !important;
      height: 125px !important;
      object-fit: cover !important;
    }
  }

  .txt-pnl {
    width: 60%;
    height: fit-content;
    padding-top: 0 !important;

    a {
      width: 100%;
    }

    h2 {
      text-transform: capitalize;
      height: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      margin: 0px 0 0 0;
    }

    p {
      margin: 8px 0;
      height: 2rem;
      font-weight: 500;
      overflow: hidden;
      font-size: 10px;
    }

    .flex-div {
      h3 {
        font-size: 12px;
        letter-spacing: 0.4px;
        text-align: left;
        width: 58%;
      }

      .add-button {
        font-size: 10px;
        color: $white;
        background-color: var(--color);
        border-radius: 6px;
        width: 85px;
        margin-right: 10px;
        margin-top: 4px;
        border-color: var(--color);
        padding: 3px 0px;
      }
      .add-button.RU,
      .add-button.FR {
        width: 150px !important;
      }
      .add-button.DE {
        width: 300px !important;
      }
    }

    @media (max-width: 330px) {
      h2 {
        margin: 0;
      }

      .flex-div {
        .add-button {
          font-size: 9px;
        }
      }
    }
  }
}

.add-cart-post.new.spa {
  .img-pnl {
    width: auto !important;
    height: auto !important;
    margin: 0;
  }
}

.section-populer {
  p {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #313131;
    font-weight: 300;
    padding: 0 1.2rem;
    height: 45px;
    font-family: Arial;
    font-size: 18px;
  }
}
.add-cart-post.new.spa .add-cart-post-inner {
  .txt-pnl {
    h2 {
      font-size: 15px;
      font-weight: normal;
      height: unset;
      text-overflow: inherit;
      white-space: normal;
    }

    p {
    }

    .flex-div {
      h3 {
        font-weight: 400;
        font-size: 14px;
      }

      .add-button {
      }
    }

    @media (max-width: 330px) {
      h2 {
      }

      .flex-div {
        .add-button {
        }
      }
    }
  }
}

.add-cart-post.new.spa.gray .add-cart-post-inner {
  background-color: #f6f6f6 !important;
  position: relative;

  .cross-btn-red {
    position: absolute;
    top: -1px;
    right: 0px;
  }
}

.add-cart-post.new.spa.gray.laun-pay .add-cart-post-inner {
  background-color: #f6f6f6 !important;
  position: relative;
  height: 85px;
  box-shadow: none;

  .img-pnl {
    width: 25%;

    img {
      height: 65px !important;
      width: 65px !important;
      object-fit: cover !important;
      border-radius: 5px !important;
      box-shadow: none !important;
    }
  }

  .txt-pnl {
    width: 75%;
    padding-left: 1 0px;

    p {
      height: unset;
      display: none;
    }

    h2 {
      color: #6d6b6b;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .cross-btn-red {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.add-cart-post.new.v2 .add-cart-post-inner {
  .img-pnl {
    height: 100%;
    box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
    border-radius: 5px;

    img {
      width: 100%;
    }
  }

  .txt-pnl {
    // h2 {
    //   font-size: 15px !important;
    // }

    p {
      height: 40px !important;
    }

    .flex-div .add-button {
      width: 75px;
      font-size: 9px !important;
    }
  }
}

.laundray-img-container {
  position: absolute;
  top: 0;
  width: 100%;

  img {
    width: 100%;
  }
}

.laundary-modal .modal-body {
  padding: 0;
  padding-left: 0;
  margin-top: 30px;
  height: unset;
  position: static;

  * {
    flex-wrap: nowrap !important;
    white-space: nowrap;
  }

  .clear {
    height: 250px;
  }

  @media (max-width: 430px) {
    .clear {
      height: 52vw;
    }
  }

  .add-item {
    margin-block: 10px 20px;
    display: flex;
    align-items: center;

    P {
      font-size: 16px;
      margin: 0px 0px 0px 20px;
    }

    i {
      font-size: 15px;
      color: var(--color) !important;
    }
  }

  .close-btn {
    position: absolute;
    right: 8px;
    top: -8px;
    font-size: 30px;
    border: none;

    .close-btn-wrap {
      width: 20px;
      height: 20px;
    }

    .icon {
      margin-right: 7px;
      margin-top: -2.5px;
    }
  }

  .count {
    font-size: 16px;
    margin: 0;
  }

  .f-16 {
    font-size: 16px;
  }

  @media (min-width: 350px) {
    .count {
      margin-right: 10px;
    }
  }

  .count-order-amount {
    width: 92px;
    line-height: 0;
    height: 27px;
    padding: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      margin: 0;
      margin-top: 2px;
      height: auto;
    }

    button {
      background-color: transparent;
      border: none;
      color: var(--color);
      font-size: 10px;
      padding: 0;
    }
  }
}

.laundary-modal.b-r .modal-dialog .modal-content {
  border-radius: 14px 14px 10px 10px !important;

  .reg-btn {
    padding: 11.5px 15px !important;
  }
}

.add-cart-post.payment .add-cart-post-inner {
  height: 175px;
  padding: 0;
  border-radius: 5px;

  .img-pnl {
    width: 36%;
    margin: 0;
    align-items: center;
    display: flex;

    img {
      border-radius: 5px 0 0 0;
      height: 90%;
    }
  }

  .txt-pnl {
    width: 60%;
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    a {
      width: 100%;
    }

    h2 {
      text-transform: capitalize;
      height: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      margin: 10px 0 0 0;
    }

    p {
      margin: 0.5rem 0;
      height: 2rem;
      font-weight: 300;
      overflow: hidden;
      font-size: 12px;
    }

    .flex-div {
      h3 {
        font-size: 12px;
        letter-spacing: 0.4px;
        text-align: left;
        width: 58%;
      }

      .add-button {
        font-size: 10px;
        color: $white;
        background-color: $dark-color;
        border-radius: 6px;
        width: 100px;
        margin-right: 10px;
        margin-top: 4px;
        border-color: $dark-color;
      }
    }

    @media (max-width: 330px) {
      h2 {
        margin: 0;
      }

      .flex-div {
        .add-button {
          font-size: 9px;
        }
      }
    }
  }

  .count-order-amount {
    margin-right: 10px;

    p {
      margin: 0;
    }
  }
}

.add-post-container.v2 {
  padding: 10px 0;
  position: relative;

  .add-cart-post.payment.v2 .add-cart-post-inner {
    height: 100%;
    padding: 0;
    border-radius: 5px;
    background-color: #f6f6f6;

    .img-pnl {
      width: 30%;
      margin: 0;
      align-items: center;
      display: flex;

      img {
        border-radius: 5px 0 0 0;
        // height: 90%;
        width: 125px;
        height: 125px;
        object-fit: cover !important;
      }
    }

    i {
      .fa-plus-square {
        color: var(--color) !important;
      }
    }

    .cross-btn-red {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .txt-pnl {
      width: 70%;
      padding-top: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      a {
        width: 100%;
      }

      .post-title {
        color: #6d6b6b;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        width: 80%;
        max-height: 2.8rem;
        min-height: 2.8rem;
        overflow: hidden;
        text-align: start;
        margin: 0;
        height: unset;
      }

      .middel2 {
        padding-bottom: 0px;
        min-height: 1.8rem;
      }

      .middel a {
        color: #6d6b6b;
        font-size: 12px;
        text-decoration: none;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      a.arrowdown::after {
        margin-top: -5px;
        content: "";
        display: block;
        box-sizing: border-box;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #7b7a7a;
        border-right: 2px solid #7b7a7a;
        transform: rotate(45deg);
        position: relative;
        right: -40px;
        top: -11px;
        cursor: pointer;
        /* display: none; */
      }

      .middel p {
        color: #979797;
        font-size: 11px;
        font-weight: 300;
        font-weight: normal;
        margin: 0;
      }

      .mystyle {
        display: block !important;
      }

      .flex-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* position: relative; */
        margin-top: 5px;

        h3 {
          font-size: 14px;
          letter-spacing: 0.4px;
          text-align: left;
        }

        .add-button {
          font-size: 10px;
          color: $white;
          background-color: $dark-color;
          border-radius: 6px;
          width: 100px;
          margin-right: 10px;
          margin-top: 4px;
          border-color: $dark-color;
        }
      }

      @media (max-width: 330px) {
        h2 {
          margin: 0;
        }

        .flex-div {
          .add-button {
            font-size: 9px;
          }
        }
      }
    }

    .count-order-amount {
      height: 26px;
      padding: 3px 8px;
      width: 110px;
      margin-right: 0px;

      p {
        margin: 0;
      }
    }
  }
}
.input-pnlm {
  width: 88%;
  margin: auto;

  p {
    font-size: 12px;
    color: #6d6b6b;
    font-weight: 500;
  }
}
.panel-drop {
  z-index: 99999999 !important;
  background-color: #f5f5f5;
  color: #000;
  max-height: 130px;
  overflow: auto;

  .p-dropdown-items .p-dropdown-item {
    color: #000;
    padding: 0.25rem 1.25rem;
  }
}

.drop-container {
  .p-focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
  }

  .p-dropdown {
    height: 40px;
    width: 100%;
    box-shadow: none;
    border-radius: 10px;

    div[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }

    .p-dropdown-label {
      padding: 5px 0 0 30px;
    }
  }
}

.extras-heading {
  background-color: #f6f6f6;
  display: flex;
  margin-block: 10px;
  padding: 7px 15px;
  justify-content: space-between;
  // position: relative;

  .text {
    color: #313131;
    font-size: 14px !important;
    margin: 0;
    font-weight: 500;
  }

  .required {
    color: red;
    font-size: 12px !important;
    padding-top: 2px;
    margin: 0;
    margin-left: 5px !important;
  }
}
.extra_input {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    border: 1px solid var(--color);
    padding: 4px;
    outline: none;
  }
}
.extras-items {
  .extras-item {
    display: flex;
    justify-content: space-between;
    padding-inline: 10px;

    .item-check input {
      width: 15px;
      height: 18px;
    }

    .item-text {
      font-weight: 400;
      font-size: 13px !important;
    }
  }
}

// .bg-layer {
//   opacity: 1;
//   background-size: cover;
//   background-position: center;
// }

// .bg-layer {
//   position: absolute;
//   top: 0;
//   left: 0;
// }
// .bg-layer.restaurant {
//   position: relative;
//   width: 100%;
//   padding-top: 56.25%;
// }

// .bg-layer,
// iframe,
// video,
// source {
//   height: 100%;
//   width: 100%;
// }
.carousel-item-custom {
  height: 100%;
}

.mb-70 {
  margin-bottom: 70px;
}
.carousel {
  overflow: visible !important;

  .control-dots {
    bottom: -15px !important;
    margin: 0 !important;

    .dot {
      background: #d6d6d6 !important;
      opacity: 1 !important;
      box-shadow: none !important;
      width: 10px !important;
      height: 10px !important;
    }

    .dot.selected {
      background-color: var(--color) !important;
      // width: 25px;
      // border-radius: 5px;
    }
  }
}

.dot-bar {
  .carousel-root {
    margin-bottom: 26px !important;

    .control-dots {
      // bottom: -22px !important;

      .dot.selected {
        background-color: var(--color) !important;
        width: 30px !important;
        height: 8px !important;
        border-radius: 5px;
      }
    }
  }

  &.less-margin {
    .carousel-root {
      margin-bottom: 30px !important;
    }
  }

  &.less-space {
    .dot {
      margin: 0 4px !important;
    }
  }
}

.book-table .carousel .control-dots {
  bottom: -25px !important;
}
.small-dots {
  .carousel-root {
    .control-dots {
      .dot {
        width: 8px !important;
        height: 8px !important;
        margin: 0 4px;
      }
    }
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect:focus {
  outline: none !important;
}

.dropdown-toggle::after {
  margin-left: 5px;
  margin-bottom: -1px;
  content: "";
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  border-bottom-color: var(--color);
  border-right-color: var(--color);
  border-top: 0;
  border-left: 0;
}

.laundary-btn {
  min-width: unset;
  border-radius: 5px;
}

.laundary-btn.cancel {
  background-color: $white;
  color: var(--color);
  border-color: var(--color);
  font-weight: normal;

  &:focus,
  &:hover,
  &:active {
    color: var(--color) !important;
    border-color: var(--color) !important;
    background-color: $white !important;
  }
}
.resturant_category .display-list li a .img-pnl img {
  object-fit: cover !important;
}
.laundary-vat {
  font-weight: normal;
}

.footer-vat {
  font-weight: normal;
  font-size: 12px;
}

.full-div .laundary-add-btn {
  width: 90%;
  margin-inline: auto;
  padding: 13px;
  position: relative;

  i {
    font-size: 10px;
    background-color: #fff;
    padding: 3px;
    border-radius: 3px;
    position: absolute;
    left: 26px;
    top: 16px;
    color: var(--color);
  }
}

// home icon
.cls-1f,
.cls-2f {
  fill: #fff;
}

.cls-2f {
  fill-rule: evenodd;
}

.cls-3f {
  fill: var(--color);
  font-size: 11px;
  // font-family: Poppins-Medium, Poppins;
  // font-weight: 600;
}

.restaurant-icon {
  width: 45px;
}

.img-pnl.smooth {
  img {
    opacity: 0;
    transition: opacity 1s ease;
  }

  img.visible {
    opacity: 1;
  }
}
.maintenances .display-list.three-items.v3 li a img {
  width: 60px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative !important;
}
.maintenances .display-list.v2.three-items li a {
  background: var(--color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85px !important;
}
.maintenances p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--color);
}
.maintenances .display-list.three-items li {
  width: 27.333333%;
  float: left;
  margin: 0 3%;
  margin-bottom: 15px;
}
.modal-add-cart {
  .plus-container {
    span {
      margin-left: 5px;
    }
  }
}

.services-option {
  position: relative;

  .closed-tag {
    position: absolute;
    right: 15px;
    top: 0px;
    background-image: url(../src/assets/images/closed-tag.png);
    background-size: 100% 100%;
    width: 50px;
    height: 60px;
    // display: none;
    z-index: 100;
  }
}
.open_at {
  color: #fff;
  position: absolute;
  top: 0;
  z-index: 1000;
  font-size: 9px;
  top: 11px;
  left: 0;
  font-weight: 500;
  width: 83px;
  text-align: center;
}
.restaurant-option {
  position: relative;

  .closed-tag {
    position: absolute;
    // right: 5px;
    // top: -9px;
    top: 4px;
    background-image: url(../src/assets/images/Badgex0.5.png);
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // width: 69px;
    // height: 80px;
    // display: none;
    // z-index: 100;
    background-repeat: no-repeat;
    width: 100px;
    height: 25px;
    display: none;
    z-index: 100;
    left: -9px;
  }

  &.closed {
    .closed-tag {
      display: inline-block;
    }
  }
}
.supermarket_box .add-cart-post.new .add-cart-post-inner {
  height: 150px !important;
}
.supermarket_box .add-cart-post.new .add-cart-post-inner .img-pnl {
  width: 140px !important;
  height: 150px !important;
}
.supermarket_box .add-cart-post.new .add-cart-post-inner .img-pnl img {
  width: 140px !important;
  height: 150px !important;
}
.booking-card .closed-tag {
  left: -4px;
}
.description-text {
  padding-inline: 15px;

  p {
    font-size: 15px;
    color: #212529;
    margin-bottom: 0;
  }
}
.hh-taxi {
  width: 100%;
  a {
    width: 100%;
  }
  img {
    width: 100%;
    height: auto !important;
  }
  h6 {
    font-size: 14px;
    padding: 10px;
    line-height: 1.5;
  }
}
.duration-div {
  padding: 5px 15px 25px 15px;

  .title {
    font-size: 14px;
    text-align: start;
    margin: 0;
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.outline-btn {
  background-color: $white;
  color: var(--color);
  border-radius: 10px;
  border-color: var(--color);
  font-weight: normal;
  padding: 10px;
  width: 100%;

  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  // justify-content: space-between !important;

  &:focus,
  &:hover,
  &:active {
    color: var(--color) !important;
    background-color: $white !important;
    border-color: var(--color) !important;
  }

  &.outline-btn.dark {
    background-color: var(--color) !important;
    color: white !important;
  }
}
.meet_price {
  width: 60px;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
}
.sta-span {
  width: 80%;
  text-align-last: left;
}
.guest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .guest_container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 17px;
    color: #000 !important;
  }
}
.search_page {
  background: #fff !important;
  .display-list.v2.three-items li a {
    box-shadow: none !important;
    background: none !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    height: auto;
  }
  .display-list.three-items.v3 li a img {
    position: relative !important;
    height: 130px !important;
    object-fit: cover;
  }
  .display-list li a .bg-layer {
    background: none !important;
  }
  .display-list.v2.three-items li a p {
    font-size: 11px;
    font-family: "Poppins-SemiBold";
    text-align: center;
    margin-top: 15px !important;
    color: #000 !important;
  }
  .img-pnl {
    background-image: none !important;
  }
}
.housekeeping-container {
  // padding-inline: 12px;
  min-height: 60vh;
  width: 92%;
  margin: auto;

  p.service {
    color: var(--color);
  }

  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .no-service {
    color: red;
    font-size: 10px;
    text-align: center;
  }

  .book {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;

    .reg-btn {
      font-weight: 500;
      border-radius: 5px;
      display: flex;
      padding-block: 6px;
      justify-content: center;
      min-width: 176px;
    }

    .tax {
      color: var(--color);
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.hk-order-container {
  .img-pnl {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    img {
      width: 150px;
    }
  }

  .text-pnl {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--color);

    h2 {
      font-size: 22px;
      letter-spacing: 0.5px;
      margin-bottom: 1rem;
      margin-top: 1rem !important;
    }

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .reg-btn {
    color: white !important;
    border: unset;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-decoration: none;
    width: 21rem;
    margin-top: 6rem;
    margin-bottom: 1.5rem;
  }
}

.no-scroll {
  overflow-y: hidden !important;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  transform: none !important;
}

.box-service-img {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0px 3px 3px #ccc;
}

.laundry-order-container {
  .img-pnl {
    display: flex;
    justify-content: center;
    // margin-top: 30px;

    img {
      width: 80px;
    }
  }

  .order-placed {
    color: var(--color);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }
}

.spa-order-placed {
  .img-pnl {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    img {
      width: 80px;
    }
  }

  .order-placed {
    color: var(--color);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }
}

.react-tel-input {
  input {
    width: 100% !important;
  }
}

.restaurant-booking {
  .booking-card {
    margin-top: 1rem;

    .bc-img {
      padding-inline: 5px;

      img {
        max-height: 200px;
        aspect-ratio: 16/9;
      }
    }

    .btn-primary:not(:disabled):not(.disabled):active {
      color: #fff;
      background-color: var(--color) !important;
      border-color: var(--color) !important;
    }

    .bc-btns {
      padding-inline: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        border-color: var(--color);
        display: flex;
        justify-content: center;
        color: $white;
        padding: 6.5px;

        // background-image: linear-gradient(0deg, #00639099 0%, #006390 74%);
        // background-color: black;
        @include grad();
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
      }

      .bc-btn-1 {
        margin-right: 5px;
        width: 66%;
      }

      .bc-btn-2 {
        width: 30%;
      }
    }

    .bc-text {
      padding-left: 10px;

      .bct-1 {
        font-size: 18px;
        font-weight: 500;
        color: var(--color);
        margin-bottom: 0px;
      }

      .bct-2 {
        color: #6d6b6b;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }
    }
  }

  hr {
    height: 1px;
    clear: unset;
    border-top: unset;
    background-color: rgba(172, 169, 169, 0.727);
    margin: 1.2rem 0 1.6rem !important;
  }

  .booking-carosle {
    .carousel {
      .slide {
        .carousel-item-custom {
          border-radius: 10px;

          img {
            border-radius: 10px;
          }
        }
      }
    }
  }
}
a.arrowdown {
  cursor: pointer;
}
.spa_discount {
  float: right;
  margin-right: 5px;
  color: #777;
  text-decoration: line-through;
}
.extra {
  color: initial;
  margin-top: 5px;
  display: inline-block;
  margin-right: 2px;
}
.book-table {
  img {
    height: 200px;
    // object-fit: cover;
    aspect-ratio: 16/9;
  }
}

.number_luggage {
  justify-content: space-between;
}

.quantity {
  display: flex;
  margin-left: -10px;
}
.payemtn-type-list .active svg path {
  fill: #52b656;
}
.quantity .removeItem {
  border: unset;
  background-color: unset;
  border-right: unset;
  border: 1px solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-color: #afafaf;
  padding: 8px;
  cursor: pointer;
}

.quantity input {
  width: 150px;
  border: 1px solid #afafaf;
  border-right: unset;
  border-left: unset;
  text-align: center;
  color: #afafaf;
  height: 39px;
  font-size: 16px;
  font-family: "Poppins-Regular";
  color: #1d1d1d;
}

.quantity .addItem {
  border: unset;
  background-color: unset;
  border-left: unset;
  border: 1px solid;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: #afafaf;
  padding: 8px;
  cursor: pointer;
}

.quantity.no-br button:last-child {
  border-left: unset;
}

.quantity.no-br button:first-child {
  border-right: unset;
}

.book-table {
  p {
    font-size: 12px;
    color: #6d6b6b;
    font-weight: 600;
    padding-inline: 20px 0;
  }

  .bk-title {
    color: #1d1d1d;
    text-align: center;
    letter-spacing: 0.7px;
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 2rem 0;
    padding: 0 !important;
  }

  input:not(.quantity > input) {
    border-radius: 10px !important;
  }

  .note {
    padding: 0;
    margin-bottom: 5px;
  }
}
.additional_title {
  color: var(--color);
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.additional_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .add_close {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: -4px;
    right: 25px;
  }
}
.additional {
  .basic_item {
    display: flex;
    // justify-content: space-between;
    margin-top: 30px;
    border-top: 1px solid #ddd;
    /* padding-top: 10px; */
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

    img {
      border-radius: 5px 0 0 0;
      width: 125px !important;
      height: 125px !important;
      object-fit: cover !important;
    }

    .inf {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-top: 15px;
      padding-left: 15px;
      justify-content: space-around;

      h6 {
        font-size: 14px !important;
        white-space: initial !important;
        display: flex;
        align-items: center;
      }
    }
  }
}
.discount_percentage {
  margin-right: 6px;
  font-size: 15px !important;
}
.laundry_price {
  font-size: 13px !important;
  color: #777;
  text-decoration: line-through;
}

// .laundry_discount_percentage {
//   margin-right: 14px;
// }
.laundry_pasic_price {
  color: #777;
  text-decoration: line-through;
  font-size: 11px;
  margin-left: 14px;
}
.additional_list {
  list-style: none;
  padding: 0;
  .add-cart-post {
    padding: 0 !important;
  }
  .add-cart-post-inner {
    top: 0 !important;
    margin-top: 15px;
  }
}
.additional_button {
  position: sticky;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  padding: 15px;

  button {
    border: none;
    padding: 10px 15px;
    border-radius: 7px;
    color: #fff;
    background-color: var(--color);
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--color);

    &:hover {
      opacity: 0.7;
    }
  }
}
.in_cart {
  width: 85px;
  height: 24px;
  align-items: center;
  margin: 0 8px;
}
.popup_catr {
  direction: rtl;

  .flex-div h3 {
    text-align: right;
  }
}
.luggage_pickup {
  p {
    // margin-left: 20px !important;
    // margin-right: -20px !important;
  }
  .bk-date,
  .bk-time,
  .number_luggage,
  .con-email,
  .con-phone {
    width: 90%;
    margin: auto;
  }
  .number_luggage {
    .quantity {
      margin-left: 0px !important;
    }
    p {
      padding-right: 5px;
    }
  }
}
.con-img-container {
  position: relative;
  padding-inline: 12px;
  margin-top: 15px;
  cursor: pointer;

  img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    height: 100% !important;
    aspect-ratio: 16 / 9;
    max-height: 135px;
    box-shadow: 0px 4px 5px #ddd;
  }

  p {
    /* background-color: rgb(0 0 0 / 21%); */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    line-height: 12px;
  }
}

.con-img-container.v3 {
  img {
    width: 100%;
    height: 100% !important;
    aspect-ratio: 16 / 9;
    max-height: unset;
    box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
  }
}

.con-text-container {
  p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;

    letter-spacing: 0.5px;
    color: var(--color);
    padding-left: 9.6px;
    margin-block: 5px;
  }

  hr {
    height: 1px;
    margin-top: 12px !important;
  }
}

.con-order-img {
  img {
    width: 100%;
    height: 100% !important;
    aspect-ratio: 16 / 9;
    max-height: 212px;
  }
}

.health-care {
  .con-img-container {
    position: relative;
    padding-inline: 12px;
    margin-top: 15px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100% !important;
      aspect-ratio: 16 / 9;
      max-height: unset;
      box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
    }

    p {
      /* background-color: rgb(0 0 0 / 21%); */
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-size: 17px;
      font-weight: 600;
      margin: 0;
      line-height: 22px;
      text-transform: uppercase;
      font-family: Arial;
      width: 100%;
      padding-inline: 20px;
      text-align: center;
    }
  }
}

.health-details {
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 5px;
    color: #212529;
    padding-inline: 19.2px;
  }
}

.modal button.close-pop {
  background-color: unset !important;
  border: unset !important;
  position: absolute !important;
  top: 10px;
  padding-inline: 10px;
  z-index: 100;
}

.body-container {
  .top {
    padding: 2rem 2rem 0;

    .title {
      font-size: 22px;
      font-weight: 600;
      text-align: start;
      line-height: 27px;
      text-transform: uppercase;
      padding: 0;
      margin-top: 0;
    }
  }

  .input-pnl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    margin: auto;

    input {
      border-radius: 10px;
    }

    input,
    textarea {
      border-color: #9d9d9d !important;
    }
  }
}

.car-text {
  p {
    margin: 0;
  }
}

.car-type {
  .p-dropdown {
    width: 90%;
  }
}
.health-cares {
  img {
    object-fit: contain;
  }
}
.dt-input {
  font-size: 12px;
  border: 1px solid #afafaf;
  text-align: center;
  display: flex;
  justify-content: center;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 18px 12px;
  // margin-top: -5px;
  height: 38px !important;

  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #7b7a7a;
    border-right: 2px solid #7b7a7a;
    transform: rotate(45deg);
    position: absolute;
    right: 7px;
  }
}

.dt-input.date-input {
  background-image: url(./assets/images/date-icon.svg);
}

.dt-input.time-input {
  background-image: url(./assets/images/time-icon.svg);
  // padding-left: 40px;
  display: flex;
  text-indent: 20px;
  align-items: center;
  justify-content: center;
}

.no-break {
  white-space: nowrap;
}

.order-total {
  p {
    font-size: 12px;
    font-weight: 600;
  }
}

.dt-global {
  font-size: 14px;
}

.txt-primary {
  color: var(--color) !important;
}

.blur {
  position: relative;
  top: 7rem;
  left: -11rem;
  height: 24rem;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.32);
  backdrop-filter: blur(1px);
  border: 2px solid white;
  border-radius: 50%;
  // left: -13rem;
}
// .p-accordion
//   .p-accordion-header
//   .p-accordion-header-link
//   .p-accordion-toggle-icon {
//   display: none !important;
// }
.view.wh {
  margin-right: 4px;
  font-size: 15px;
  /* width: 35px; */
  padding: 1px 8px;
}
.p-accordion-content p {
  margin: 0 !important;
  font-size: 13px;
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  color: #fff !important;
  // border: none;
  box-shadow: none;
}
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  position: absolute;
  right: 10px;
}
.p-accordion-header-text {
  line-height: 1;
  font-size: 15px;
  font-weight: 600;
}
.pad1020 {
  padding: 10px 35px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 0px;
  transition: none;
  // width: 100%;
  // border: none;
  /* text-align: left; */
  outline: none;
  font-size: 14px;
  transition: 0.4s;
  margin-bottom: 0rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  font-weight: 500;
  letter-spacing: 0.4px;
  // font-family: "Poppins-Medium";
  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  color: white;
  background: var(--color) !important;
  padding: 13px 15px;
  margin-bottom: 6px;
}
.p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  color: #fff !important;
  border: none !important;
}
.p-accordion .p-accordion-tab {
  position: relative;
  margin-bottom: 4px;
}
.p-accordion-content i {
  position: absolute;
  top: 11px;
  color: #fff;
  right: 10px;
}

// .p-accordion-tab-active::after {
//   content: "-" !important;
//   position: absolute;
//   color: #fff;
//   right: 13px;
//   top: 6px;
//   font-size: 21px;
// }
// .p-accordion-tab::after {
//   content: "+";
//   position: absolute;
//   color: #fff;
//   right: 13px;
//   top: 6px;
//   font-size: 21px;
// }
.p-accordion-tab:hover {
  color: #fff !important;
  border: none !important;
}
.p-accordion-header:hover {
  // color: #fff !important;
  border: none !important;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  color: #fff !important;
  border: none !important;
}
.house_price {
  margin-left: 20px;
  // font-size: 13px;
  // font-weight: 700;
}

.acor_house {
  width: 100% !important;
}
.car_rental .display-list li button p,
.display-list li a p {
  position: relative !important;
}
.housekeeping-container .p-accordion .p-accordion-content {
  padding: 15px !important;
}
.housekeeping-container
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  background-color: #ffffff !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border-color: var(--color) !important;
  font-weight: normal !important;
  padding: 13px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;

  .p-accordion-header-text {
    font-weight: 100;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    // color: var(--color);
  }
}
.agree {
  display: flex;
  align-items: center;
}

.mcdonald .bg-layer {
  background: none !important;
}
.rate_dev {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .reg-btn.big {
    width: 150px !important;
    min-width: 150px !important;
    // margin: 12px !important;
    margin-top: 0px;
    background: #fff;
    color: var(--color);
  }

  .pricerate {
    color: var(--color);
    font-size: 15px;
    font-weight: 500;
  }
}
.ancilaries tr.detailes td {
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Poppins", sans-serif;
}
.ancilaries input {
  width: 15px !important;
  height: 15px !important;
}
.ancilaries td {
  border: none !important;
}
.mcdonald.display-list li a p,
.mcdonald.res_sp.display-list li a p {
  color: #000 !important;
  position: relative !important;
  font-weight: 500;
  font-size: 11px;
  margin-top: 10px !important;
  font-size: 11px;
  font-family: "Poppins-SemiBold";
}
.mcdonald.display-list.v2.two-items li a,
.mcdonald.res_sp.display-list.v2.two-items li a {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  background: none !important;
  box-shadow: none !important;
}
.mcdonald.display-list.v2 li {
  margin-bottom: 20px;
}
// .phamacy.suber_market_cat.display-list.v2.three-items li a {
//   height: 150px !important;
// }
.phamacy.suber_market_cat.display-list.v2.three-items li a p {
  margin-top: 0 !important;
}
.phamacy.display-list li a .bg-layer {
  background: none !important;
}
.phamacy.display-list.three-items.v3 li a img {
  height: 120px;
}

.phamacy.display-list.v2 li {
  margin-bottom: 20px;
}
.mcdonald.display-list li a .img-pnl {
  position: relative !important;
  width: 100%;
  height: 100%;
}
.housekeeping-container
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background-color: var(--color) !important;
  // color: !important;
}
.desc {
  text-align: left;
  text-align: left;
  width: 100%;
  margin-top: -10px;
  border-bottom: 1px solid #d1c7c7;
  padding-bottom: 5px;
  font-size: 12px;
}
.housekeeping-container .p-accordion.selected .p-accordion-header-link:hover,
.housekeeping-container .p-accordion.selected .p-accordion-header-link {
  background-color: var(--color) !important;
  color: #fff !important;
}

.housekeeping-container
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.housekeeping-container .p-accordion-tab:hover,
.housekeeping-container .p-accordion-header:hover,
.housekeeping-container .p-accordion-header-link:hover {
  background-color: #ffffff !important;
  color: var(--color) !important;
}
.base-back ul {
  position: relative;
  top: -13rem;
  border-radius: 48%;
  padding-left: 37%;
  /* background-color: rgb(229 229 229 / 55%); */
  /* backdrop-filter: blur(1px); */
  padding-top: 14%;
  list-style: none;
  position: absolute;
  left: -30%;
  top: 14rem;
  /* width: 82%; */
  /* height: 39%; */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
  font-weight: 600;
}
tr.main {
  background: var(--color);
  color: #fff;
}
@media (min-width: 500px) {
  .blur {
    left: -17rem;
  }
}

@media (max-width: 500px) and (min-width: 400.5px) {
  .blur {
    left: -15rem;
  }
}

@media (max-width: 435px) and (min-width: 400.5px) {
  .blur {
    left: -13rem;
  }
}

.base-back {
  .list-of-icon {
    margin: auto;
    z-index: 100;
    position: absolute;
    top: 17rem;
    left: 3rem;
  }

  @media (min-width: 480px) {
    .list-of-icon {
      left: 3.5rem;
    }
  }

  @media (max-width: 480px) {
    .list-of-icon {
      left: 5rem;
    }
  }

  @media (max-width: 465px) {
    .list-of-icon {
      left: 3.5rem;
    }
  }

  @media (max-width: 445px) {
    .list-of-icon {
      left: 2.5rem;
    }
  }

  @media (max-width: 435px) {
    .list-of-icon {
      left: 4rem;
    }
  }

  @media (max-width: 425px) {
    .list-of-icon {
      left: 3rem;
    }
  }

  @media (max-width: 410px) {
    .list-of-icon {
      left: 2rem;
    }
  }

  @media (max-width: 400px) {
    .list-of-icon {
      left: 4rem;
    }
  }

  @media (max-width: 391px) {
    .list-of-icon {
      left: 3rem;
    }
  }

  @media (max-width: 378px) {
    .list-of-icon {
      left: 2rem;
    }
  }

  @media (max-width: 360px) {
    .list-of-icon {
      left: 1rem;
    }
  }

  @media (max-width: 340px) {
    .list-of-icon {
      left: 0rem;
    }
  }

  .list-of-icon a.one {
    position: relative;
    right: -1.5rem;
    top: -6rem;
  }

  .list-of-icon a.tow {
    position: relative;
    top: -4rem;
    right: -7rem;
    width: 9.5rem;
  }

  .list-of-icon a.three {
    position: relative;
    top: -1rem;
    right: -9rem;
    width: 9.5rem;
  }

  .list-of-icon a.four {
    position: relative;
    top: 2rem;
    right: -8rem;
  }
  .list-of-icon a.five {
    position: relative;
    top: 4rem;
    right: -5rem;
    width: 9.5rem;
  }
  .list-of-icon a.six {
    position: relative;
    top: 4.2rem;
    right: 0rem;
  }

  .image-box {
    width: 40px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    width: auto;
    margin-bottom: 0px !important;
  }

  .flex p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px !important;
  }

  ul li {
    margin-top: 15%;
  }

  ul li .name {
    font-size: 16px;
    margin-bottom: 3px;
  }

  ul li .des {
    font-size: 16px;
    font-weight: 500;
  }

  ul li .des .flag {
    width: 25px;
    height: 15px;
    margin-top: -2px;
  }
}
.add-cart-post.new .add-cart-post-inner .txt-pnl h2,
.subermarket_items .add-cart-post.new.v2 .add-cart-post-inner .txt-pnl h2 {
  height: 40px !important;
  font-size: 14px !important;
  white-space: initial !important;
  display: flex;
  align-items: center;
}
.subermarket_items .add-cart-post.new.v2 .add-cart-post-inner .txt-pnl p {
  height: 33px !important;
}
.subermarket_items .add-cart-post.new.v2 .add-cart-post-inner .txt-pnl span {
  height: 15px !important;
  display: block;
}
.title {
  text-align: center;
  margin: 1rem 0 0.5rem 0;
  font-weight: 500;
  color: #1d1d1d;
  font-size: 16px;
}

.about-us {
  .info {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    padding: 7px 10px 7px 20px;

    p {
      color: var(--color);
      font-weight: 500;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 17px;
    }

    svg {
      margin-right: 20px;
    }
  }

  .cls-1info {
    fill: var(--color);
  }

  .cls-1ex {
    fill: var(--color);
  }

  .cls-1lo {
    fill: var(--color);
  }

  .desc {
    padding: 10px 20px;
    color: #000;
    font-size: 14px;
    line-height: 25px;
  }

  .sub-title {
    color: var(--color);
    font-size: 12px;
    padding-left: 1.4rem;
    margin-top: 15px;
    padding-right: 5px;
  }

  table {
    color: #000;

    font-size: 12px;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border-spacing: 0;
    border-collapse: collapse;
  }

  table td,
  table th {
    border: 0.5px solid #707070 !important;
    padding: 0.5rem;
    text-align: center;
    font-size: 12px;
    font-family: "Poppins-Regular";
    /* border-top: 0.5px solid #707070 !important; */
    /* border-bottom: 0.5px solid #707070 !important; */
    /* border-left: 0.5px solid #707070 !important; */
    /* border-right: 0.5px solid #707070 !important; */
  }

  tbody tr td:first-child {
    width: 60%;
  }
}

#Icon_awesome-tags {
  fill: var(--color) !important;
}

.v2 {
  p {
    margin: 0 !important;
    color: var(--color);
  }

  .code-parent {
    padding: 0 1rem 1rem 1rem;
  }

  .code-section {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .code-section .left {
    display: flex;
    align-items: center;
  }

  .code-section .left p {
    font-size: 12px;
    font-weight: 500;
    padding-left: 8px;
  }

  .code-section .middel input {
    width: 110px;
    border: 1px solid white;
    border-radius: 5px;
  }

  .code-section .right button {
    border-radius: 5px;
    padding: 6px 15px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    font-weight: 300;
    border: none;
    background-color: var(--color) !important;
  }
}

.discount {
  padding: 0 1rem;

  h2 {
    font-size: 12px;
    font-weight: 600;
    color: #6d6b6b;
  }
}

.total {
  padding: 0 1rem;

  h2 {
    color: #313131;
    font-size: 12px;
    font-weight: 600;
  }
}

.payment {
  margin: 0;

  h2 {
    color: black;
    font-weight: 600;
    /* font-family: arial; */
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.rounded-form {
  input {
    border: 1px solid #afafaf;
    border-radius: 10px;
    padding: 10px 20px;
  }
  select {
    border: 1px solid #afafaf;
    border-radius: 10px;
    padding: 10px 20px;
  }
}

.meeting .reg-btn {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 15px;
  align-items: center;

  .meet_price {
    display: flex;
    align-items: center;
    .cou {
      margin: 0 10px;
    }
    .dec,
    .inc {
      font-size: 17px;
    }
  }
}
.iframe_layar {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  opacity: 0.51;
  z-index: 2;
  background-position: 50%;
  background-size: cover;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 70px;
}
.meeting .book .reg-btn {
  justify-content: center !important;
}
.agree {
  input {
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
  a {
    color: var(--color);
    font-weight: 500;
    font-size: 13px;
  }
  margin: 0;
  color: #9d9d9d;
  font-size: 12px;

  .form-check {
    margin: 0;
    padding: 0;
    // margin-bottom: 5px;
    .form-check-input {
      width: 13px;
      height: 13px;
      margin: 0;
      margin-right: 4px;
    }
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none !important;
}

.dark-placeholder {
  input {
    font-size: 12px !important;

    padding: 10px 20px;
  }

  ::placeholder {
    color: #3e3e3e !important;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3e3e3e !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3e3e3e !important;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 50vw !important;
    padding: 0;
    right: 10px !important;
    top: 10px !important;
    left: unset !important;
    margin: 0;
  }
}

.checkout-header {
  img {
    width: 80px;
  }

  h2 {
    font-size: 16px;
  }
}

.co-header {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #212529 !important;
  padding-left: 10px !important;
}

.dp-item-inner {
  display: flex;

  img {
    height: 30px;
    width: 30px;
  }
}

.spa-inputs {
  input {
    border: 1px solid #afafaf;
    border-radius: 10px;
    margin: auto;
    padding: 6px 20px;
    margin-bottom: 1rem !important;
    width: 100% !important;
    height: 40px;
    color: #000;
    font-size: 12px;
  }

  input::placeholder {
    font-size: 12px;
  }
}
.res_sp.display-list li a .img-pnl {
  position: relative !important;
  width: 100%;
  height: 100%;
}
.res_sp.display-list.v2.two-items li a {
  height: 100%;
}
.res_sp.display-list li a p {
  position: absolute !important;
}
.panel-drop.therapist {
  border: 1px solid #7b7a7a;
  background-color: white;
  box-shadow: none;
  padding: 0;
  margin-top: 0.5rem;

  border-radius: 5px;

  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item {
      padding: 4px 10px;
    }

    .p-dropdown-item:not(:last-child) {
      border-bottom: 1px solid #7b7a7a;
    }
  }
}
.maintenance.body-container .input-pnl {
  width: 85% !important;
  padding-top: 10px;
}

.maintenance h4 {
  margin-top: 30px;
  text-align: center;
  color: var(--color);
  font-size: 22px;
}
.chose_img_mani {
  background-color: var(--color);
  opacity: 0.7;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  width: 150px;
  text-align: center;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: black !important;
  background: unset !important;
}

.car-drop-flex {
  .car-drop {
    width: 31.4%;
    display: flex;
    justify-content: center;

    .dropdown-toggle {
      position: relative;
      font-family: Arial;
      margin-top: 0.3rem;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem !important;
      border: 0.5px solid var(--color) !important;
      color: var(--color) !important;
      border-radius: 5px;
      margin: auto;
      padding: 5px 12px 5px 5px;
      // margin-bottom: 1rem !important;
      width: 100% !important;
      min-height: 35px;
      height: auto;
    }

    .dropdown-toggle::after {
      margin-top: -2px;
      content: "";
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      position: absolute;
      right: 10px;
      border-color: var(--color);
    }

    .dropdown-menu {
      position: absolute;
      background-color: white;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 999;
      /* border: 1px solid #9D9D9D; */
      box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      margin-top: 10px;
      max-height: 500px;
      overflow-y: scroll;

      a {
        padding: unset;
        border: unset;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
        cursor: pointer;
        user-select: none;
        border-bottom: 1px solid #9d9d9d42;
        padding: 0.5rem;
        font-size: 12px;
        font-weight: 400;
        color: $dark-color;
      }
    }
  }

  .car-drop.show {
    .dropdown-toggle::after {
      margin-top: 5px;
      border-bottom: unset;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(-45deg);
    }
  }
}

.i-f-small {
  input,
  input::placeholder,
  textarea,
  textarea::placeholder {
    font-size: 12px !important;
  }
}

input#destination {
  background-image: url(./assets/images/location.svg);
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: 18px 12px;
}

.taxi-input {
  padding-left: 10px;

  input.three {
    border: 1px solid #afafaf;
    color: #9d9d9d !important;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }
}

.car-type-drop {
  .p-dropdown {
    border: none !important;
    background-color: #f5f5f5;
    color: $dark-color;

    .p-dropdown-label.p-placeholder,
    .p-dropdown-trigger {
      color: $dark-color;
    }
  }

  .p-dropdown:not(.p-disabled):hover {
    border: none !important;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border: none;
  }
}

.car-panel-drop {
  box-shadow: none;
  z-index: 99999999 !important;
  background-color: #f5f5f5;
  color: #000;
  max-height: 130px;
  overflow: auto;
}

.cls-1w {
  fill: #fff;
}

.cls-2w {
  fill: var(--color);
}

.car-img-container {
  img {
    width: 100%;
    height: 100% !important;
    aspect-ratio: 16 / 9;
    max-height: 212px;
    border-radius: 10px;
    object-fit: cover;
    // margin-bottom: 5px;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.5);
  }
}

.modal-item {
  .txt-pnl {
    h2 {
      padding: 0 1rem;
      font-size: 14px;
      margin-top: 1rem;
      color: #1d1d1d;
      font-weight: 500;
      margin: auto;
      width: 100%;
      text-transform: capitalize;
      letter-spacing: 0.6px;
      line-height: 14px;
      margin-top: 1rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 14px;
      padding: 0 1rem;
      color: var(--color);
      font-weight: normal;
    }
  }
}

.car-input-container {
  padding-inline: 7px;

  input {
    border-radius: 10px;
    border-color: #afafaf;
  }
}

.color-btn {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &.active {
    border-color: gray;
    box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 16%);
  }
}

.only {
  font-weight: 600 !important;
  font-size: 13.5px !important;
  color: black !important;
}

.salon-table {
  padding-inline: 10px;

  table {
    p {
      margin: 0;
    }

    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    font-size: 12px;

    tr.main {
      background-color: var(--color);
      color: #fff;

      th {
        padding: 5px;
      }
    }

    tr.detailes td p:first-child,
    tr.detailes td {
      font-size: 12px;
      color: #000;
      letter-spacing: 0.5px;
    }

    tr.detailes td {
      padding: 10px 5px;
    }

    tr.detailes td p:last-child {
      font-size: 12px;
      color: #000;
    }

    tr.detailes td p:first-child,
    tr.detailes td {
      font-size: 12px;
      color: #000;
      letter-spacing: 0.5px;
    }

    tr.detailes td p:last-child {
      font-size: 12px;
      color: #000;
    }

    tr.detailes td p:first-child,
    tr.detailes td {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      letter-spacing: 0.5px;
    }

    input {
      width: 23px;
      height: 23px;
    }

    td,
    th {
      border: 1px solid #707070;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;
      border-top-color: rgb(112, 112, 112);
      border-right-color: rgb(112, 112, 112);
      border-bottom-color: rgb(112, 112, 112);
      border-left-color: rgb(112, 112, 112);
      border-image-source: initial;
      border-image-slice: initial;
      border-image-width: initial;
      border-image-outset: initial;
      border-image-repeat: initial;
    }
  }
}

.selects-div select {
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  margin-bottom: 14px;
}

.f-dropdown33 select,
.f-dropdown22 select,
.f-dropdown11 select {
  display: none;
}

.f-dropdown33,
.f-dropdown22,
.f-dropdown11 {
  --max-scroll: 3;
  position: relative;
  z-index: 10;
}

.f-dropdown33 ul,
.f-dropdown22 ul,
.f-dropdown11 ul {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  max-height: calc(var(--max-scroll) * 46px);
  top: 36px;
  left: 0;
  z-index: 1;
  right: 0;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transform-origin: 0 0;
  transition: opacity 0.2s ease, visibility 0.2s ease,
    transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  transform: translate(0, 5px);
  border-top: unset;
}

.hidden {
  display: none;
}

.f-dropdown33 ul li,
.f-dropdown22 ul li,
.f-dropdown11 ul li {
  padding: 0;
  margin: 0;
}

.f-dropdown33 ul li:first-child a,
.f-dropdown22 ul li:first-child a,
.f-dropdown11 ul li:first-child a {
  border-radius: 0px;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.f-dropdown33 ul li a,
.f-dropdown22 ul li a,
.f-dropdown11 ul li a {
  cursor: pointer;
  display: block;
  padding: 4px 12px;
  color: #000;
  text-decoration: none;
  outline: none;
  position: relative;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.f-dropdown33 ul li a img,
.f-dropdown22 ul li a img,
.f-dropdown11 ul li a img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  image-rendering: auto;
  aspect-ratio: 2;
  padding: 2px;
}

.f-dropdown33.filled > span,
.f-dropdown22.filled > span,
.f-dropdown11.filled > span {
  color: #000;
  padding: 10px 10px 10px 30px;
}

.f-dropdown33 > span,
.f-dropdown22 > span,
.f-dropdown11 > span {
  cursor: pointer;
  padding: 9px 12px;
  border-radius: 10px;
  z-index: 100;
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  color: #000 !important;
  font-weight: 500;
  position: relative;
  padding: 7px 10px 7px 30px;
  border: 1px solid #c3c3c3;
  background: #fff;
  transition: color 0.2s ease, border-color 0.2s ease;
}

.f-dropdown33 > span img,
.f-dropdown22 > span img,
.f-dropdown11 > span img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 2px;
  image-rendering: auto;
}

.f-dropdown11 > span:before,
.f-dropdown11 > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 2px;
  border-radius: 1px;
  top: 50%;
  right: 12px;
  transition: all 0.3s ease;
  font-weight: 600;
  background-color: #9d9d9d !important;
}

.f-dropdown11 > span:after {
  transform: scale(0.96, 0.8) rotate(-50deg);
}

.f-dropdown11 > span:before {
  margin-right: 9px;
  transform: scale(0.96, 0.8) rotate(50deg);
}

.f-dropdown11.open > span:before {
  transform: scale(0.96, 0.8) rotate(-50deg);
}

.f-dropdown11.open > span:after {
  transform: scale(0.96, 0.8) rotate(50deg);
}

.f-dropdown22 > span > span,
.f-dropdown11 > span > span,
.f-dropdown22 > span > span,
.f-dropdown33 > span {
  font-size: 14px;
  letter-spacing: 0.7px;
  font-weight: 400;
}

.f-dropdown33.open ul,
.f-dropdown22.open ul,
.f-dropdown11.open ul {
  opacity: 1;
  box-shadow: 1px 2px 4px #d6d6d6 !important;
  visibility: visible;
  transform: translate(0, 12px);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
}

.f-dropdown33 > span > span,
.f-dropdown22 > span > span,
.f-dropdown11 > span > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
}

.note {
  padding: 0 2rem;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;

  p {
    color: #1d1d1d;
    font-family: "Poppins-Regular";
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-bottom: 0.5rem;
    padding-left: 5px;
  }

  .time-select {
    width: 100%;
    height: 35px;
    border: 1px solid #afafaf;
    border-radius: 9px;
    margin-bottom: 10px;
    background-color: white;
    font-size: 12px;
    padding: 0 20px;
    color: #afafaf;
    font-family: "Poppins-Regular";
  }
}

// TAXI DROPDOWN
.f-dropdown {
  width: 95%;
  --max-scroll: 4;
  position: relative;
  z-index: 1;
  box-shadow: unset;
}

.f-dropdown select {
  display: none;
}

.f-dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  max-height: calc(4 * 46px);
  top: 25px;
  left: 0;
  z-index: 1;
  right: 0;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transform-origin: 0 0;
  transition: opacity 0.2s ease, visibility 0.2s ease,
    transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  transform: translate(0, 5px);
  border-top: unset;
}

.f-dropdown ul li {
  border-bottom: 0.5px solid #c1c1c1;
  width: 75%;
  margin: auto;
  /* margin-bottom: 10px; */
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 500;
}
.open_card {
  background-color: var(--color);
  color: #fff;
  cursor: pointer;
  width: 25px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 3px;
}
.f-dropdown ul li {
  padding: 0;
}
.meal_card {
  background-color: #fff;
  border-radius: 5px;
  height: 125px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: height 0.3s ease-out;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}
.meal_card.active {
  height: auto;
  transition: height 0.3s ease-in;
  // transition: all 0.5s ease-in-out;
}
.meal_post_inner {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.group {
  padding: 5px;
}
.f-dropdown ul li a {
  cursor: pointer;
  display: block;
  padding: 4px 12px 4px 0px;
  color: var(--color) !important;
  text-decoration: none;
  outline: none;
  position: relative;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.f-dropdown ul li a img {
  height: 28px;
  width: 31px;
  margin-right: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  aspect-ratio: 2;
  padding: 4px;
  text-align: center;
}

.f-dropdown > span {
  cursor: pointer;
  padding: 9px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: var(--color) /*!important*/;
  /* font-weight: 600; */
  position: relative;
  /* border: 1px solid #F5F5F5; */
  border-bottom: unset;
  background: #f5f5f5;
  /* box-shadow: 1px 2px 4px #d6d6d6!important; */
  transition: color 0.2s ease, border-color 0.2s ease;
}

.f-dropdown > span:before,
.f-dropdown > span:after {
  background-color: var(--color);
  color: var(--color);
}

.f-dropdown > span:before {
  margin-right: 4px;
  transform: scale(0.96, 0.8) rotate(50deg);
}

.f-dropdown > span:before,
.f-dropdown > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 2px;
  border-radius: 1px;
  top: 50%;
  right: 12px;
  /* background: #00638e; */
  transition: all 0.3s ease;
  /* color: #00638e; */
  /* font-weight: 600; */
  /* background-color: #00638e; */
}

.f-dropdown > span:before,
.f-dropdown > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 2px;
  border-radius: 1px;
  top: 50%;
  right: 12px;
  /* background: #00638e; */
  transition: all 0.3s ease;
  /* color: #00638e; */
  /* font-weight: 600; */
  /* background-color: #00638e; */
}

.spa-inputs {
  input[type="time"] {
    background-image: url(./assets/images/time-icon.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 18px 12px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type="date"] {
    background-image: url(./assets/images/date-icon.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 18px 12px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.f-dropdown > span:after {
  transform: scale(0.96, 0.8) rotate(-50deg);
}

.f-dropdown.open > span:before {
  transform: scale(0.96, 0.8) rotate(-50deg);
}

.f-dropdown.open > span:after {
  transform: scale(0.96, 0.8) rotate(50deg);
}

.f-dropdown.open {
  z-index: 15;
}

.f-dropdown.open ul {
  opacity: 1;
  box-shadow: 1px 2px 4px #d6d6d6 !important;
  visibility: visible;
  transform: translate(0, 12px);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.f-dropdown > span img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 2px;
}

.f-dropdown > span > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
  /* padding-left: 56px; */
}

.cls-133 {
  fill: #000000;
}

.cls-333 {
  fill: #000000;
}

.cls-333,
.cls-433 {
  opacity: 0.16;
}

.cls-433 {
  fill: #fff;
}

.cls-533 {
  filter: url(#Path_373);
}

.cls-1time {
  fill: var(--color);
}

.cls-1date {
  fill: none;
}

.cls-2date {
  fill: #000000;
}

// .cls-1order2 {
//   opacity: 0.17;
// }

.cls-2order2 {
  fill: var(--color);
}

.cls-111 {
  fill: var(--color);
}

.cls-211 {
  clip-path: url(#clip-path);
}

.cls-311 {
  fill: #000000;
}

.cls-311,
.cls-411 {
  opacity: 0.22;
}

.cls-411 {
  fill: #fff;
}

.cls-511 {
  filter: url(#Path_375);
}

// .mrow {
//   --bs-gutter-x: 1.5rem;
//   --bs-gutter-y: 0;
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: calc(var(--bs-gutter-y) * -1);
//   margin-right: calc(var(--bs-gutter-x) * -0.5);
//   margin-left: calc(var(--bs-gutter-x) * -0.5);
// }
// .col-12 {
//   flex: 0 0 auto;
//   width: 100%;
// }
.orders {
  padding-inline: 12px;

  svg.order1 {
    position: relative;
    // z-index: 2;
    top: 0rem;
    margin-bottom: -3rem;
  }

  svg.order3 {
    float: right;
    position: relative;
    top: -69px;
    // z-index: -1;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: -4rem;
  }

  .box {
    border-color: #000000;
    position: relative;
  }

  .box {
    margin-top: -1rem;
    width: 100%;
    border: 0.5px solid;
    border-radius: 10px;
    padding: 10px;
    height: 135px;
    // height: 8rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-color: var(--color);
  }

  .flex {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: -5px;
  }

  .flex > * {
    display: inline-block;
  }

  .flex .top-title p {
    max-height: 2.8rem;
    overflow: hidden;
  }

  .flex .top-title span {
    display: block;
    font-size: 12px;
    padding-left: 8px;
  }

  .flex p,
  .flex .top-title span,
  .datetime span {
    color: var(--color);
  }

  span.orange {
    border: 1px solid;
    color: white !important;
    border-radius: 5px;
    width: max-content;
    text-align: center;
    padding: 0px 10px !important;
    margin-left: 8px;
  }

  .flex p {
    font-size: 14px;
    font-weight: 600;
    padding: 0 8px;
    margin: 0;
  }

  .price {
    width: 25%;
    // padding: 0;
    text-align: right;
  }

  .flex .top-title {
    width: 75%;
    padding-right: 0;
  }

  .flex .top-title {
    height: 3.8rem;
    overflow: hidden;
  }

  .middel {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    // margin-top: -4rem;
    // margin-bottom: -1.8rem;
    position: absolute;
    right: 9rem;
    top: 50%;
    transform: translateY(-50%);
    fill: var(--color);
    opacity: 0.5;
  }

  svg.order2 {
    width: 7rem;
  }

  .datetime {
    display: flex;
    justify-content: space-between;
    width: 56%;
    padding: 0 8px;
  }

  a.view {
    background-color: var(--color) !important;
  }

  a.view {
    border: unset;
    color: white;
    border-radius: 5px;
    padding: 5px 12px;
    text-decoration: none;
    letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 11px;
    cursor: pointer;
    z-index: 22;
  }

  .datetime span {
    font-size: 12px;
    margin-left: 2px;
  }
}
.orders_btns {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.react-tel-input {
  .form-control {
    height: 42px !important;
    border: 1px solid #afafaf !important;
    border-radius: 10px !important;
  }

  .flag-dropdown {
    border-radius: 10px 0 0 10px !important;
  }
}
.wh_o {
  margin-right: 4px !important;
  font-size: 15px !important;
  /* width: 35px !important; */
  padding: 2px 8px !important;
}
.spa_form {
  // .form-check-label {
  //   min-width: 30px;
  // }
  .form-check {
    display: flex;
    flex-direction: row-reverse;
  }

  .form-check-input {
    position: relative;
    margin: 0 !important;
  }

  .form-check-label {
    margin-right: 7px;
  }
}

.laundry_page {
  .react-tel-input .form-control {
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
  }
}

.ref_loading {
  position: absolute;
  width: 50px;
  height: 50px;
  // background: red;
  bottom: 25%;
  // // z-index: 1055;
  right: 0;
}

.spinner-border {
  border: 0.25em solid var(--color);
  border-right-color: transparent;
}
.suber_bt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  button {
    text-decoration: none;
    color: white;
    border: unset;
    padding: 5px 35px;
    display: inline-block;
    margin: auto;
    margin-top: 5px;
    border-radius: 5px;
    margin: 2px;
  }
  button:disabled {
    opacity: 0.6;
  }
  .Accept {
    background: #04ab0d;
  }
  .Decline {
    background: #e4000c;
  }
}
.min_amou {
  display: block;
  font-size: 9px;
  color: #777;
  font-weight: 500;
  text-decoration: line-through;
}
.red {
  color: red !important;
}
.green_img {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
}

.super {
  padding: 10px;
  .new {
    display: flex;
    justify-content: space-between;
    .new_p {
      color: red;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .old {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      color: #777;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
}

.btn-primary.is_green:not(:disabled):not(.disabled):active {
  background-color: #4cdf30 !important;
  border-color: #4cdf30 !important;
  color: #fff !important;
}

.outline-btn.outline-btn.dark.is_green {
  background-color: #4cdf30 !important;
  border-color: #4cdf30 !important;
  color: #fff !important;
}

.is_green img {
  filter: brightness(0) invert(1);
}

.flowers img {
  object-fit: contain !important;
}

.brand img {
  height: 35px !important;
}

.base-back {
  /* background-image: url({{ getFrontSideBarImage() }}); */
  // background-image: url(../src/assets/images/icons/background-menu.webp);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-color: #3a6a91; */
  /* height: 100vh; */
  height: 48vh;
}

.h-aut {
  min-height: initial !important;
}
.base-back .container {
  margin: 0;
  padding: 0;
}
.grid_info .row {
  width: 100%;
  margin: 0;
  padding: 0;
}
.top-info .info div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-info {
}
.main_title img {
  width: 2rem;
  height: 1rem;
  margin-left: 5px;
  border-radius: 3px;
}
.top-info .info {
  margin-bottom: 1.5rem;
}
.top-info .info:last-child {
  margin-bottom: 0rem;
}
.top-info .info div img {
  width: 12px;
  text-align: center;
  margin-right: 6px;
}
.top-info .info div img.last {
  width: 17px;
}
.top-info .info div p.sub_title {
  font-size: 14px;
  margin-bottom: 3px;
  color: white;
  font-family: "Poppins";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px;
  letter-spacing: 0.31px;
}
.top-info .info p.main_title {
  font-size: 18px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  text-align: center;
  color: white;
  letter-spacing: 1px;
}
.grid_info .row .col-6 {
  width: 50%;
  padding: 5rem 30px;
}
.grid_info .row .col-6 img {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 22px;
  margin: auto;
  margin-bottom: 10px;
}
.grid_info .row .col-6 .title {
  font-size: 16px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.grid_info .row .col-6 .sub {
  font-size: 12px;
  font-family: "Poppins-Medium";
  text-align: center;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.grid_info,
.top-info {
  // background: rgba(255, 255, 255, 0.09);
  opacity: 1;
}

.grid_info .row .col-6.wh {
  opacity: 0.82;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  background: rgba(0, 83, 119, 0.09);
  /* background: linear-gradient(-108deg, rgba(0,83,119,0.09 ) 0.00%, rgba(0,83,119,0.55 ) 100.00%); */
  background-image: linear-gradient(
    -108deg,
    var(--color_m_s) 0%,
    var(--color_m_c) 100%
  );
}

.grid_info .row .col-6.gr {
  opacity: 0.82;
  // background: var(--color);
  /* background: linear-gradient(-108deg, rgba(0, 83, 119, 1) 0.00%, rgba(0,83,119,0.55 ) 100.00%); */
  background-image: linear-gradient(
    -108deg,
    var(--color) 0%,
    var(--color_m_c) 100%
  );
}

.city {
  color: #fff;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  img {
    width: 13px;
    margin-right: 5px;
  }
}
// .bluer {
//   padding: 20px 40px;
//   background-color: #ddd;
// }
.wrapper_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;

  .left {
    background: #726c6c69;
    width: 160px;
    height: 115px;
    border-radius: 20px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    background: #726c6c69;
    height: 115px;
    padding: 7px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.check_m {
  width: auto !important;
  font-size: 12px !important;
  margin: 0 !important;
  min-width: auto !important;
  padding: 5px 15px !important;
}
.weather {
  display: flex;
  justify-content: space-between;
  div {
    color: #fff;
    display: flex;
    align-items: center;
  }
  p {
    margin: 0;
    font-size: 13px;
    font-family: "Poppins-Medium";
  }
  img {
    width: 18px;
    margin-right: 4px;
  }
}
.info_ {
  div {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  p {
    margin: 0;
  }
  img {
    width: 14px;
    margin-right: 4px;
  }
}
.main_title {
  color: #fff;
  font-size: 19px !important;
  font-family: "Poppins-Medium";
}
.box_min_container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  background-color: transparent;
  padding: 20px;
  // gap: 14px;
  // justify-content: space-between;
  position: fixed;

  bottom: 40px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  // height: 286px;
  // position: absolute;
  // bottom: -20px;
  width: 100%;
}
.box_ {
  background-color: var(--color);
  width: 31%;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  display: flex;
  // height: 110px;
  height: 6.5rem;
  border-radius: 10px;
  margin: 1%;

  img {
    width: 22px;
    // height: 30px;
    object-fit: contain;
    margin: auto;
    display: flex;
  }
  .title_ {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    font-family: "Poppins-Medium";
    // text-transform: uppercase;
  }
}
.check_box {
  color: #fff;
  p {
    margin: 0;
  }
  .sub {
    color: #fff;
    text-decoration: none;
  }
  .sub:hover {
    color: #fff;
  }
}

@media (max-width: 415px) {
  .wrapper_container {
    padding: 25px 10px;
  }
  .wrapper_container .left {
    width: 145px;
  }
  .box_min_container {
    // gap: 11px;
    padding: 20px 10px;
  }
  .box_ .title_ {
    font-size: 13px;
  }
}
//Arabic
.ar_lang {
  direction: rtl;

  .tar {
    text-align: right !important;
  }
  .carousel-root {
    direction: ltr;
  }
  .modal-body {
    direction: rtl !important;
    text-align: right !important;
  }

  .dropdown-toggle::after {
    margin-right: 5px;
    margin-left: 0px;
  }

  .cart-container {
    svg {
      transform: scaleX(-1);
    }
  }
  .back-btn {
    svg {
      transform: scaleX(-1);
      margin-right: initial;
      margin-left: 5px;
    }
  }
  .cls-3f {
    direction: ltr;
  }
  .bc-btns {
    padding-inline: 0px !important;
    .bc-btn-1 {
      margin-right: 0;
      margin-left: 5px;
    }
  }
  .add-cart-post-inner {
    p {
      text-align: right !important;
    }
  }
  .bc-text {
    text-align: right !important;
    padding-right: 10px;
  }
  .book_ {
    text-align: right !important;
  }
  .quantity {
    direction: ltr;
    margin-right: -15px;
  }
  .m_16 {
    margin-right: 9px;
  }
  .txt-pnl {
    h3 {
      text-align: right !important;
    }
    h2 {
      text-align: right !important;
    }

    .add-button {
      margin-left: 10px;
      margin-right: initial;
    }
  }
  .cart-count {
    right: initial;
    left: -7px;
  }

  .nav-link {
    img {
      margin-right: initial;
      margin-left: 2px;
    }
  }
  .housekeeping-container {
    .service {
      text-align: right !important;
    }
  }

  .car-drop {
    .dropdown-menu {
      text-align: right !important;
    }
  }
  .modal-body {
    direction: rtl;
  }
  .modal-text-container {
    direction: rtl;
    div {
      padding-right: 20px;
    }
    p {
      text-align: right;
    }
    h2 {
      text-align: right;
    }
  }
  .padd-div {
    text-align: right;
  }
  .con-text-container {
    p {
      text-align: right;
      padding-right: 15px;
    }
  }
  .react-tel-input {
    .form-control {
      padding-left: 62px;
      direction: ltr;
    }
  }
  .flag-dropdown {
    left: 0;
    .selected-flag {
      width: 55px;
    }
    .flag {
      position: absolute;
      top: 50%;
      left: 17%;
      margin-top: -5px;
    }
  }
  .spa_form {
    p {
      text-align: right;
    }
  }
  .reg-btn.service {
    margin-left: 10px;
  }
  .description-text {
    text-align: right;
    padding-right: 20px;
  }
  .f-dropdown11 {
    span:before {
      right: 88%;
    }
    span::after {
      right: 88%;
    }
  }
  .p-dropdown-item {
    text-align: right;
  }
  .p-dropdown {
    padding-right: 15px;
  }
  .modal-content {
    .reg-btn {
      margin-right: 4px;
    }
  }
  .cross-btn-red {
    left: 10px !important;
  }
  .health-details {
    text-align: right;
  }
  .taxi_err {
    margin-right: 63%;
    margin-left: initial;
  }
  .home_page {
    .base-back .list-of-icon a.tow {
      right: -5rem;
    }
    .base-back .list-of-icon a.one {
      right: -10.5rem;
    }
    .base-back .list-of-icon a.four {
      right: -4rem;
    }
    .base-back .list-of-icon a.three {
      right: -3rem;
    }
    ul {
      right: 4%;
      top: 12rem;
      left: initial;

      li {
        text-align: right;
      }
    }
    .blur {
      right: -14rem;
      left: initial;
    }
  }
  .about-us .sub-title {
    text-align: right;
    padding-right: 24px;
  }
  .about-us .desc {
    text-align: right;
  }
  .about-us .info svg {
    margin-right: initial;
    margin-left: 20px;
  }
  .orders .flex .top-title {
    text-align: right;
  }
  .orders .price {
    text-align: left;
  }
  .view {
    i {
      transform: rotate(180deg);
    }
  }
  .number_luggage {
    justify-content: initial;
    .quantity {
      margin-right: 25px;
    }
  }
  .book-table {
    label {
      float: right;
    }
  }
  .note p {
    text-align: right;
  }
  // .book-table p {
  //   padding-inline: initial;
  // }
  .display-list li a p {
    letter-spacing: 0px;
  }
  .p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    right: initial;
    left: 10px;
  }
  .inline-list.search {
    direction: ltr;
  }
  .p-accordion .p-accordion-content {
    text-align: right;
  }
  .sta-span {
    text-align-last: right;
  }
  .hh-taxi h6 {
    text-align: right;
  }
}
